<template>
  <v-checkbox
    value="1"
    :label="variable"
    type="checkbox"
    required
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot
      slot="append-outer"
      name="append-outer"
    />
  </v-checkbox>
</template>

<script>
  export default {
    name: 'CheckBoxFieldVariable',
    props: {
      variable: Boolean,
    },
  }
</script>

<style lang="sass">
  //
</style>
