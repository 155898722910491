<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to add an appointment without Booking
// Props:
// - v-model: Activate the dialog box
// - resourceList: Provide the list of ressource (Array of ressource)
// Events:
// - addAppointmentWithoutBooking: This event is triggered when the Appointment is added. In the event, the appointement data are passed.
// the event is concatining the following format: { type: 'AddedEvent', color: 'jean', start: dateFetched, end: dateFetchedPlusDuration, startISO: DateTime.fromJSDate(dateFetched).toISO(), endISO: DateTime.fromJSDate(dateFetchedPlusDuration).toISO(), name: myAppointment.serviceName, timed: true, enduserFirstName: myAppointment.enduserFirstName, enduserLastname: myAppointment.enduserLastname, enduserPhone: myAppointment.enduserPhone, enduserMail: myAppointment.enduserMail, resourceID: myAppointment.resourceID, resourceFirstname: myAppointment.resourceFirstname, resourceLastname: myAppointment.resourceLastname, appointementId: myNewAppointment.data.createAppointment.id, appointmentAnswersId: '' }
// Example of usage:
  <kdw-dialog-add-appointment-without-booking
    v-model="dialogAddAppointmentWithoutBooking"
    :resourceList="resourceList"
    @addAppointmentWithoutBooking="addAppointmentWithoutBooking($event)"
  />
  with
// ------------------------------------------------------------------------------>

<template>
  <v-theme-provider :dark="dark">
    <v-dialog
      v-model="dialogStatus"
      max-width="500px"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card>
        <base-info-card :title="$t('addAppointmentWithoutBooking.mainTitle')" />
        <v-card-text
          v-if="resourceListOrganised.length > 1"
        >
          <v-autocomplete
            v-model="selectedResource"
            :items="resourceListOrganised"
            item-text="name"
            item-value="id"
            prepend-icon="mdi-account-key"
            :label="$t('addAppointmentWithoutBooking.resource')"
            outlined
            @change="resourceSelectionChanged"
          />
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="selectedServiceListForResource"
                :items="serviceListForResource"
                item-text="name"
                item-value="id"
                prepend-icon="mdi-ballot"
                :label="$t('addAppointmentWithoutBooking.service')"
                outlined
                @change="selectedServiceListForResourceChanged"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="serviceDuration"
                type="number"
                class="mx-0 my-0"
                outlined
                dense
                :label="$t('serviceSP.DurationMM')"
                :hint="$t('serviceSP.DurationMM_hint')"
                prepend-icon="mdi-calendar-week"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row class="black--text font-weight-bold">
            {{ $t('worktimeDay.start') }}
          </v-row>
          <kdw-Date-Picker
            :key="'Date-Picker' + trickDisplay"
            v-model="dateNewAppointment"
            @dateValidated="dateValidated()"
          />
        </v-card-text>
        <v-card-text>
          <v-row class="black--text font-weight-bold">
            {{ $t('worktimeDay.start') }}
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                key="startHH"
                v-model="startHH"
                class="text-field-single-line-label-top text-field-single-line-prepend-append-margin-top"
                :items="staticHours"
                item-text="value"
                item-value="value"
                dense
                prepend-icon="mdi-clock-time-eight"
                :label="$t('serviceSP.DurationHH_hint')"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                key="startMM"
                v-model="startMM"
                class="text-field-single-line-label-top text-field-single-line-prepend-append-margin-top"
                :items="staticMinutes"
                item-text="value"
                item-value="value"
                dense
                prepend-icon="mdi-clock-time-eight"
                :label="$t('serviceSP.DurationMM_hint')"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row class="black--text font-weight-bold">
            {{ $t('addAppointmentWithoutBooking.guess') }}
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <kdw-text-field
                id="IDfirstname"
                v-model="enduserFirstName"
                :label="$t('identity.firstname')"
                prepend-icon="mdi-account-circle"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <kdw-text-field
                id="IDlastname"
                v-model="enduserLastname"
                :label="$t('identity.lastname')"
                prepend-icon="mdi-account-circle"
              />
            </v-col>
          </v-row>
          <v-row>
            <kdw-vue-tel-input
              v-model="enduserPhone"
            />
          </v-row>
          <v-row>
            <kdw-text-field
              id="IDmail"
              v-model="enduserMail"
              :label="$t('identity.mail')"
              :rules="rules"
              prepend-icon="mdi-email"
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <kdw-btn
            text
            @click="closeDialog"
          >
            {{ $t('button.cancel') }}
          </kdw-btn>
          <v-spacer />
          <kdw-btn
            @click="validateAppointment"
          >
            {{ $t('button.validateQuestion') }}
          </kdw-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-theme-provider>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'
  import { listServiceResourcesSimplifiedWithService } from '@/graphql/kdwqueries'
  import { Logger, API } from 'aws-amplify'
  import { buildMinutesTable } from '@/helpers/GenericFunctionsTime'
  import { convertAWSDateTimeStringLocalUTC } from '@/helpers/Formatter'
  import { createAppointment as createAppointmentMutation } from '@/graphql/mutations'
  import { DateTime } from 'luxon'

  const logger = new Logger('AddAppointmentWithoutBooking')

  export default {
    name: 'AddAppointmentWithoutBooking',

    mixins: [Heading],

    props: {
      value: {
        type: Boolean,
        required: true,
      },
      dark: Boolean,
      resourceList: {
        type: Array,
        required: true,
      },
    },

    data: () => {
      return {
        dialogStatus: false,
        resourceListOrganised: [],
        selectedResource: '',
        serviceListForResource: [],
        selectedServiceListForResource: '',
        serviceDuration: 0,
        trickDisplay: 0,
        staticHours: [{ value: '00' }, { value: '01' }, { value: '02' }, { value: '03' }, { value: '04' }, { value: '05' }, { value: '06' }, { value: '07' }, { value: '08' }, { value: '09' }, { value: '10' }, { value: '11' }, { value: '12' }, { value: '13' }, { value: '14' }, { value: '15' }, { value: '16' }, { value: '17' }, { value: '18' }, { value: '19' }, { value: '20' }, { value: '21' }, { value: '22' }, { value: '23' }],
        staticMinutes: [],
        dateNewAppointment: '',
        startHH: '09',
        startMM: '00',
        enduserFirstName: '',
        enduserLastname: '',
        enduserPhone: '',
        enduserMail: '',
        rules: [
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        ],
      }
    },

    watch: {
      value: function (newValue) {
        // console.log('new value.................................', newValue)
        if (newValue) {
          // When it is requested to open the dialog
          this.resourceListOrganised.splice(0, this.resourceListOrganised.length)
          for (var i = 0; i < this.resourceList.length; i++) {
            this.resourceListOrganised.push({ name: this.resourceList[i].firstname + ' ' + this.resourceList[i].lastname, id: this.resourceList[i].id, firstname: this.resourceList[i].firstname, lastname: this.resourceList[i].lastname })
          }
          if (this.resourceList.length > 0) {
            this.selectedResource = this.resourceList[0].id
            this.selectServices(this.selectedResource)
          }
          // console.log(this.resourceListOrganised)
          this.dialogStatus = true
        }
      },
    },

    created: function () {
      this.dialogStatus = false
      buildMinutesTable(this.staticMinutes)
    },

    methods: {
      emitResult () {
        this.$emit('input', this.dialogStatus)
      },
      closeDialog () {
        this.dialogStatus = false
        this.emitResult()
      },
      resourceSelectionChanged () {
        // console.log('resourceSelectionChanged')
        this.selectServices(this.selectedResource)
      },
      selectServices (resourceID) {
        try {
          // We make the list of the elements to be deleted in
          const myFilter = {
            resourceID: { eq: resourceID },
          }
          API.graphql({ query: listServiceResourcesSimplifiedWithService, variables: { filter: myFilter }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            .then((apiData) => {
              // console.log(apiData)
              const apiDataSimplified = apiData.data.listServiceResources.items
              // console.log(apiDataSimplified)
              this.serviceListForResource.splice(0, this.serviceListForResource.length)
              for (var i = 0; i < apiDataSimplified.length; i++) {
                this.serviceListForResource.push({ name: apiDataSimplified[i].service.name, id: apiDataSimplified[i].service.id, duration: parseInt(apiDataSimplified[i].service.serviceDuration) + parseInt(apiDataSimplified[i].service.bufferTimeBefore) + parseInt(apiDataSimplified[i].service.bufferTimeAfter) })
              }
              if (this.serviceListForResource.length > 0) {
                this.selectedServiceListForResource = this.serviceListForResource[0].id
                // console.log('duration:', this.resourceList[0].duration)
                this.serviceDuration = this.serviceListForResource[0].duration
              }
            })
        } catch (err) {
          logger.error(err)
        }
      },
      selectedServiceListForResourceChanged () {
        // We need to update the duration.
        var found = false
        var i = 0
        while ((found === false) && (i < this.serviceListForResource.length)) {
          if (this.selectedServiceListForResource === this.serviceListForResource[i].id) {
            found = true
          }
          i++
        }
        // we adjust the duration
        if (found) {
          i--
          this.serviceDuration = this.serviceListForResource[i].duration
        }
      },
      validateAppointment () {
        // console.log('this.dateNewAppointment:')
        // console.log(this.dateNewAppointment)
        if (this.dateNewAppointment === '') {
          this.$root.kdwsnackbar.alert('addAppointmentWithoutBooking.lackDate', 3000)
          return
        }
        var myAppointment = {}

        myAppointment.dateTime = convertAWSDateTimeStringLocalUTC(this.dateNewAppointment, this.startHH, this.startMM)
        // console.log('myAppointment.dateTime:')
        // console.log(myAppointment.dateTime)
        var found = false
        var i = 0
        // we are searching for the ressource index
        while ((found === false) && (i < this.resourceListOrganised.length)) {
          if (this.resourceListOrganised[i].id === this.selectedResource) {
            found = true
          }
          i++
        }
        if (found) {
          i--
          myAppointment.resourceID = this.selectedResource
          myAppointment.resourceFirstname = this.resourceListOrganised[i].firstname
          myAppointment.resourceLastname = this.resourceListOrganised[i].lastname
        }
        i = 0
        found = false
        // Now we are searching for the service
        while ((found === false) && (i < this.serviceListForResource.length)) {
          if (this.selectedServiceListForResource === this.serviceListForResource[i].id) {
            found = true
          }
          i++
        }
        // we adjust the duration
        if (found) {
          i--
          myAppointment.serviceID = this.selectedServiceListForResource
          myAppointment.serviceName = this.serviceListForResource[i].name
          myAppointment.serviceDuration = this.serviceListForResource[i].duration
        }

        myAppointment.enduserID = ''
        // We need to validate that we have at least a lastname or firstname
        if ((this.enduserFirstName === '') && (this.enduserLastname === '')) {
          this.$root.kdwsnackbar.alert('addAppointmentWithoutBooking.lackNameInfo', 3000)
          return
        }
        myAppointment.enduserFirstName = this.enduserFirstName
        myAppointment.enduserLastname = this.enduserLastname
        // We validate that we need to have a point of contact
        if (((this.enduserPhone === undefined) || (this.enduserPhone === '')) && (this.enduserMail === '')) {
          this.$root.kdwsnackbar.alert('addAppointmentWithoutBooking.lackContactInfo', 3000)
          return
        }
        myAppointment.enduserPhone = this.enduserPhone
        myAppointment.enduserMail = this.enduserMail
        // console.log(myAppointment)
        // We are creating the appointment
        API.graphql({ query: createAppointmentMutation, variables: { input: myAppointment }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          .then((myNewAppointment) => {
            this.$root.kdwsnackbar.alert('addAppointmentWithoutBooking.okCreationAppoint', 3000)
            this.closeDialog()
            // We need to add to the event list.
            var dateFetched = new Date(this.dateNewAppointment + 'T' + this.startHH + ':' + this.startMM + ':00')
            var dateFetchedPlusDuration = new Date(DateTime.fromJSDate(dateFetched).plus({ minutes: myAppointment.serviceDuration }))
            var event = { type: 'AddedEvent', color: 'jean', start: dateFetched, end: dateFetchedPlusDuration, startISO: DateTime.fromJSDate(dateFetched).toISO(), endISO: DateTime.fromJSDate(dateFetchedPlusDuration).toISO(), name: myAppointment.serviceName, timed: true, enduserFirstName: myAppointment.enduserFirstName, enduserLastname: myAppointment.enduserLastname, enduserPhone: myAppointment.enduserPhone, enduserMail: myAppointment.enduserMail, resourceID: myAppointment.resourceID, resourceFirstname: myAppointment.resourceFirstname, resourceLastname: myAppointment.resourceLastname, appointementId: myNewAppointment.data.createAppointment.id, appointmentAnswersId: '' }
            // console.log('event before')
            // console.log(event)
            // We emit an event for the parent component
            this.$emit('addAppointmentWithoutBooking', JSON.stringify(event))
            // return
          })
        // this.$root.kdwsnackbar.alert('addAppointmentWithoutBooking.koCreationAppoint', 3000)
      },
      dateValidated () {
        // console.log('date validated')
        this.trickDisplay++
      },
    },
  }
</script>
