import WebFontLoader from 'webfontloader'

// async load fonts
WebFontLoader.load({
  google: {
    families: [
      'PT+Sans:400,700&display=swap',
      'Work+Sans:100,300,400,500,700,900&display=swap',
    ],
  },
  custom: {
    families: ['Material+Icons', 'Gotham Medium'],
    urls: ['https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css'],
  },
})

// <link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900" rel="stylesheet">
// <link href="https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css" rel="stylesheet">
