import { API, Logger, Storage } from 'aws-amplify'
import { createRequest as createRequestMutation, createAnswer as createAnswerMutation, updateRequest as updateRequestMutation, updateAnswer as updateAnswerMutation, createServiceRequests as createServiceRequestsMutation, sendEmailGQL } from '@/graphql/mutations'
import { getEndUserRequestAnswers, serviceRequestsBySPIDExtractRequestAnswer, getAnswerAppointmentDetails } from '@/graphql/kdwqueries'
import { formatAWSDateTimeIntoDateTime, formatServicePrice } from '@/helpers/Formatter'
import { provideExpirationDate } from '@/helpers/GenericFunctions'
import { getUserSub, getUserSubSync } from '@/services/User'
import { requestState, answerState, RequestStateMachine, AnswerStateMachine } from '@/services/StateMachine'
import { FILEHEADERQUOTATION } from '@/services/File'
import i18n from '@/plugins/i18n.js'
import { DateTime } from 'luxon'
import { deleteAppointment } from '@/services/Appointment'
import { sendEmail } from '@/services/commonTools'
import { createFixedPriceRequest as backEndcreateFixedPriceRequest, createQuotationRequest as backEndcreateQuotationRequest, onSiteQuotationRequest as backEndOnSiteQuotationRequest, upsertAnswer, cancelledByEU as backEndCancelledByEU } from '@/services/stateMachineBackend'

const logger = new Logger('RequestService')

// We are assuming that the file is selected
async function updateQuotationFile (myAnwser, requestID, answerID, myRequestQuotationFileName) {
  try {
    // console.log('updateQuotationFile')
    // console.log(myAnwser)
    // console.log(requestID)
    // console.log(answerID)
    // console.log(myRequestQuotationFileName)

    // No new file to manage, so we get out
    if (myAnwser.quotationFile === null) {
      return 'error'
    }
    // On construit le nom du fichier à traiter:
    const filename = await getUserSub() + ',' + requestID + ',' + answerID + FILEHEADERQUOTATION + myAnwser.quotationFile.name

    // On verifie qu'il ne s'agit pas du même ficher
    if (myRequestQuotationFileName === filename) {
      alert(i18n.t('requestLib.alertQuotationIdentical'))
      return 'error'
    }

    // 1. We load the new quotation
    // On ne garde pas le private: Storage.put(filename, myAnwser.quotationFile, { level: 'private' })
    await Storage.put(filename, myAnwser.quotationFile)

    // 2. We delete the old quotation if necessary
    if (myAnwser.quotation !== '') {
      // console.log('We delete the old file')
      Storage.remove(myRequestQuotationFileName)
    }
    // We update the quotation file name
    // TODO this logic moved to sm: const myAnswer = { id: answerID, quotation: filename }
    // TODO this logic moved to sm: await API.graphql({ query: updateAnswerMutation, variables: { input: myAnswer }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
    // console.log('everything went fine')
    return filename
  } catch (err) {
    logger.error('updateQuotationFile')
    logger.error(err)
    return 'error'
  }
}

async function _sendEmail (templateName, templateParams) {
  var output = {}
  try {
    templateParams = JSON.stringify(templateParams)
    logger.info('sending email templateName:' + templateName)
    output = await API.graphql({ query: sendEmailGQL, variables: { templateName, templateParams }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
    logger.info('send email result:' + JSON.stringify(output))
   } catch (err) {
    logger.error('send email error: ', err)
    output.errorMessage = err.message
    throw new Error(output.errorMessage)
   }
}

export class RequestService {
  requests = []
  // answers = []
  // answersSelected = []
  indexRequest = 0

  constructor () {
    logger.info('RequestService created.')
  }

  length () {
    return this.requests.length
  }

  updateReviewId (requestNb, answerNb, reviewId) {
    if (this.requests[requestNb].answers[answerNb].review === null) this.requests[requestNb].answers[answerNb].review = {}
    this.requests[requestNb].answers[answerNb].review.id = reviewId
  }

  async fetchRequestAndAnswersEU () {
    try {
      logger.info('fetchRequestAndAnswers from EU')
      var apiData, listRequests
      var qna = null
      var price = null
      var projectName = null
      var myRequestStateMachine, myAnswerStateMachine
      var appointmentId = ''
      var appointmentAnswerId = ''
      var appointmentDateTime = ''
      var appointmentDateTimeISO = ''
      var resourceID = ''
      var resourceFirstname = ''
      var resourceLastname = ''
      var onlineBooking = false
      // console.log('userId: ', await getUserSub())
      // var nextTokenStart = 'start' --> No next token to manage on getUser

      // while (nextTokenStart !== null) {
        // if (nextTokenStart === 'start') {
          apiData = await API.graphql({ query: getEndUserRequestAnswers, variables: { id: await getUserSub(), limit: 4000 }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        // } else {
        //  await API.graphql({ query: getEndUserRequestAnswers, variables: { id: await getUserSub(), limit: 4000, nextToken: nextTokenStart }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        //  }

        // console.log(apiData)
        listRequests = apiData.data.getEndUser.requests.items
        // console.log('listRequests:')
        // console.log(listRequests)
        this.requests.splice(0, this.requests.length)
        // this.answersSelected.splice(0, this.answersSelected.length)
        if ((listRequests.length > 0)) {
          // console.log('find some request for this user')
          // We are formatting the dates to be displayed properly and preparing for the answers table
          for (var i = 0; i < listRequests.length; i++) {
            appointmentId = ''
            appointmentAnswerId = ''
            appointmentDateTime = ''
            appointmentDateTimeISO = ''
            resourceID = ''
            resourceFirstname = ''
            resourceLastname = ''
            onlineBooking = false
            qna = null
            projectName = null
            if (listRequests[i].qna !== null) {
              qna = JSON.parse(listRequests[i].qna)
              // Search for the project name:
              for (var k = 0; qna.questions && k < qna.questions.length; k++) {
                // I am searching for the tag 'name' pour point out the project name.
                if (qna.questions[k].tag === 'name') {
                  projectName = qna.questions[k].givenAnswer
                  // console.log(projectName)
                }
              }
            }
            // console.log('find some request for this user1')
            switch (listRequests[i].answers.items.length) {
              case 0: price = formatServicePrice(listRequests[i].isFixedPrice, 0); break
              default:
                for (var z = 0; z < listRequests[i].answers.items.length; z++) {
                  // price = listRequests[i].answers.items[0].servicePrice
                  myAnswerStateMachine = new AnswerStateMachine(listRequests[i].answers.items[z].state, listRequests[i].isFixedPrice)
                  listRequests[i].answers.items[z].state = myAnswerStateMachine
                  listRequests[i].answers.items[z].serviceName = listRequests[i].serviceName
                  // We change the status as this is an onsite quotation, so the quote is not yet known even if we have 0 in it.
                  if (myAnswerStateMachine.getCurrentState() === answerState.onSiteQuotation) {
                    listRequests[i].answers.items[z].servicePrice = formatServicePrice(false, 0)
                  }
                }
                break
            }
            // console.log('find some request for this user2')
            // We are checking if there is an appointment associated:
            if (listRequests[i].isFixedPrice) {
              if (listRequests[i].answers !== undefined) {
                if (listRequests[i].answers.items.length > 0) {
                  if (listRequests[i].answers.items[0].onlineBooking !== undefined) {
                    onlineBooking = listRequests[i].answers.items[0].onlineBooking
                  }
                  if (listRequests[i].answers.items[0].appointments !== undefined) {
                    if (listRequests[i].answers.items[0].appointments.items.length > 0) {
                      if (listRequests[i].answers.items[0].appointments.items[0].appointment !== undefined) {
                        appointmentAnswerId = listRequests[i].answers.items[0].appointments.items[0].id
                        appointmentId = listRequests[i].answers.items[0].appointments.items[0].appointment.id
                        appointmentDateTime = DateTime.fromISO(listRequests[i].answers.items[0].appointments.items[0].appointment.dateTime).setLocale(i18n.locale).toFormat('yyyy-LLL-dd HH:mm')
                        appointmentDateTimeISO = listRequests[i].answers.items[0].appointments.items[0].appointment.dateTime
                        resourceFirstname = listRequests[i].answers.items[0].appointments.items[0].appointment.resourceFirstname
                        resourceLastname = listRequests[i].answers.items[0].appointments.items[0].appointment.resourceLastname
                        resourceID = listRequests[i].answers.items[0].appointments.items[0].appointment.resourceID
                      }
                    }
                  }
                }
              }
            }
            // console.log('find some request for this user3')
            // We are creating a state machine for this request.
            myRequestStateMachine = new RequestStateMachine(listRequests[i].state, listRequests[i].isFixedPrice)
            // console.log(listRequests[i])
            // I am creating the request:
            // console.log('find some request for this user4:', listRequests[i].services)
            // We are checking the service ID:
            if (listRequests[i].services.items.length === 0) {
              this.requests.push({ createdAtOrigin: listRequests[i].createdAt, createdAt: formatAWSDateTimeIntoDateTime(listRequests[i].createdAt), datetimeExpiry: formatAWSDateTimeIntoDateTime(listRequests[i].datetimeExpiry), qna: qna, isFixedPrice: listRequests[i].isFixedPrice, serviceName: listRequests[i].serviceName, catName: listRequests[i].category.nameTag, stateRequest: myRequestStateMachine, serviceLegaldescription: listRequests[i].serviceLegaldescription, answers: listRequests[i].answers.items, servicePrice: price, id: listRequests[i].id, projectName: projectName, isProposalSP: listRequests[i].isProposalSP, show: (myRequestStateMachine.isDisabled() === true) ? false : listRequests[i].isFixedPrice, appointmentId: appointmentId, appointmentAnswerId: appointmentAnswerId, appointmentDateTime: appointmentDateTime, resourceFirstname: resourceFirstname, resourceLastname: resourceLastname, onlineBooking: onlineBooking, resourceID: resourceID, appointmentDateTimeISO: appointmentDateTimeISO, categoryID: listRequests[i].categoryID })
            } else {
              this.requests.push({ createdAtOrigin: listRequests[i].createdAt, createdAt: formatAWSDateTimeIntoDateTime(listRequests[i].createdAt), datetimeExpiry: formatAWSDateTimeIntoDateTime(listRequests[i].datetimeExpiry), qna: qna, isFixedPrice: listRequests[i].isFixedPrice, serviceName: listRequests[i].serviceName, catName: listRequests[i].category.nameTag, stateRequest: myRequestStateMachine, serviceLegaldescription: listRequests[i].serviceLegaldescription, answers: listRequests[i].answers.items, servicePrice: price, id: listRequests[i].id, projectName: projectName, isProposalSP: listRequests[i].isProposalSP, show: (myRequestStateMachine.isDisabled() === true) ? false : listRequests[i].isFixedPrice, appointmentId: appointmentId, appointmentAnswerId: appointmentAnswerId, appointmentDateTime: appointmentDateTime, resourceFirstname: resourceFirstname, resourceLastname: resourceLastname, onlineBooking: onlineBooking, serviceId: listRequests[i].services.items[0].service.id, resourceID: resourceID, appointmentDateTimeISO: appointmentDateTimeISO, categoryID: listRequests[i].categoryID })
            }
          }
          // console.log('this.requests:')
          // console.log(this.requests)
          // We would like to sort the data by descending date
          this.sortDescendingRequest()
          // console.log('this.requests sorted:')
          // console.log(this.requests)
        } else {
          logger.info('find no request for this user')
        }
      // }
    } catch (err) {
      logger.error(err)
    }
  }

// sorting by order creation - Ascending.
sortAscendingRequest () {
  this.requests.sort((d1, d2) => new Date(d1.createdAtOrigin).getTime() - new Date(d2.createdAtOrigin).getTime())
}

// sorting by order creation - Descending.
sortDescendingRequest () {
  this.requests.sort((d1, d2) => new Date(d2.createdAtOrigin).getTime() - new Date(d1.createdAtOrigin).getTime())
}

sortCategoryRequest () {
  this.requests.sort((a, b) => (a.catName > b.catName) ? 1 : ((b.catName > a.catName) ? -1 : 0))
}

async fetchRequestAndAnswersSP () {
  try {
    // logger.info('fetchRequestAndAnswers from SP')
    this.requests.splice(0, this.requests.length)
    // We filter against the SP connected
    /*
    const myFilter = {
      serviceProviderID: {
        eq: await getUserSub(),
      },
    }
    */
    // var nextTokenStart = 'start'
    // var apiData,
    var listServices
    var apiData1
    var appointmentId = ''
    var appointmentAnswerId = ''
    var appointmentDateTime = ''
    var appointmentDateTimeISO = ''
    var resourceID = ''
    var resourceFirstname = ''
    var resourceLastname = ''
    var onlineBooking = false
    // INUTILE - while (nextTokenStart !== null) {
      // INUTILE - if (nextTokenStart === 'start') {
      // INUTILE -   apiData = await API.graphql({ query: listServicesRequestUser, variables: { filter: myFilter, limit: 4000 }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      // INUTILE - } else {
      // INUTILE -   await API.graphql({ query: listServicesRequestUser, variables: { filter: myFilter, limit: 4000, nextToken: nextTokenStart }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      // INUTILE - }
      // console.log('apiData:')
      // console.log(apiData)

      apiData1 = await API.graphql({ query: serviceRequestsBySPIDExtractRequestAnswer, variables: { serviceProviderID: await getUserSub(), limit: 4000 }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      // console.log('apiData1 from fetchRequestAndAnswersSP:')
      // console.log(apiData1)
      // --> Error nextTokenStart = apiData.data.ServiceRequestsBySPID.nextToken
      // INUTILE - nextTokenStart = apiData.data.listServices.nextToken
      // --> Changed: listServices = apiData.data.listServices.items
      listServices = apiData1.data.ServiceRequestsBySPID.items
      // console.log('listServices from fetchRequestAndAnswersSP')
      // console.log(listServices)
      if ((listServices.length > 0)) {
        var listRequest, listAnswers, myStateMachine, servicePrice, serviceLegaldescription, noteCustomer, notePersonal
        // var answer = []
        var qna = null
        // console.log('listServices: >>[%j] ', listServices[0])
        for (var i = 0; i < listServices.length; i++) {
          listRequest = listServices[i].request
          appointmentId = ''
          appointmentAnswerId = ''
          appointmentDateTime = ''
          appointmentDateTimeISO = ''
          resourceID = ''
          resourceFirstname = ''
          resourceLastname = ''
          onlineBooking = false
          if (!listRequest) {
            // CHECKME: perhaps the request has been manually deleted by enduser but we forgot to unlink that request from serviceRequest??
            // anyway let's continue to move to the next record
            // continue
          }
          // INUTILE - if (listRequest.length > 0) {
            // console.log('listRequest' + i)
            // console.log('listRequest: >> ' + listRequest)
            // INUTILE - for (var j = 0; j < listRequest.length; j++) {
              // console.log('listRequest.enduser' + i + ':' + j)
              // console.log(listRequest[j].endUser)
              listAnswers = listRequest.answers.items
              // console.log('listAnswers before clean up')
              // console.log(listAnswers)
              // We compute the servicePrice
              servicePrice = null
              serviceLegaldescription = ''
              noteCustomer = ''
              notePersonal = ''
              // if (listAnswers.length > 0) {
              if (listRequest.isFixedPrice === true) {
                if (listAnswers.length === 1) { // We have a single answer for a fixed price request. This is expected.
                  servicePrice = listAnswers[0].servicePrice
                  // answer = [...listAnswers]
                  myStateMachine = new AnswerStateMachine(listAnswers[0].state, listRequest.isFixedPrice)
                  // We are adding the appointment information if necessary
                  onlineBooking = listAnswers[0].onlineBooking
                  if (listAnswers[0].appointments !== undefined) {
                    if (listAnswers[0].appointments.items.length > 0) {
                      if (listAnswers[0].appointments.items[0].appointment !== undefined) {
                        appointmentAnswerId = listAnswers[0].appointments.items[0].id
                        // console.log(appointmentAnswerId)
                        appointmentId = listAnswers[0].appointments.items[0].appointment.id
                        // console.log(listAnswers[0].appointments.items[0].appointment)
                        appointmentDateTime = DateTime.fromISO(listAnswers[0].appointments.items[0].appointment.dateTime).setLocale(i18n.locale).toFormat('yyyy-MM-dd HH:mm')
                        appointmentDateTimeISO = listAnswers[0].appointments.items[0].appointment.dateTime
                        // console.log(listAnswers[0].appointments.items[0].appointment.dateTime)
                        resourceFirstname = listAnswers[0].appointments.items[0].appointment.resourceFirstname
                        resourceLastname = listAnswers[0].appointments.items[0].appointment.resourceLastname
                        resourceID = listAnswers[0].appointments.items[0].appointment.resourceID
                      }
                    }
                  }
                } else { // if we have a more than 1 single answer for a fixed price request. This is not expected.
                  logger.error('A fixed price service is is containing 0 or >=2 answer, which is not normal')
                }
              } else {
                // We are on a non fixed price service.
                // console.log('We need to clean the answers which are not from this SP')
                for (var k = 0; k < listAnswers.length; k++) {
                  if (listAnswers[k].serviceProvider.id !== await getUserSub()) {
                    // console.log('slice...................')
                    // console.log(listAnswers[k])
                    listAnswers.splice(k, 1)
                    k = -1
                    // logger.info('I clean up answer k:' + k)
                    // console.log('slice...................')
                  }
                }
                // console.log('listAnswers after clean up')
                // console.log(JSON.stringify(listAnswers))
                // console.log('listRequest[j].state:')
                // console.log(listRequest[j].request.state)
                // console.log('We are going into the switch case')
                switch (listAnswers.length) {
                  case 0: servicePrice = i18n.t('requestLib.toQuote')
                          switch (listRequest.state) {
                            case requestState.cancelledbyEU: myStateMachine = new AnswerStateMachine(answerState.requestCancelledbyEU, listRequest.isFixedPrice)
                                                            break
                            case requestState.expired: myStateMachine = new AnswerStateMachine(answerState.requestExpired, listRequest.isFixedPrice)
                                                      break
                            default: myStateMachine = new AnswerStateMachine(answerState.pendingQuotation, listRequest.isFixedPrice)
                                    break
                          }
                          break
                  case 1: // console.log('We are on a non fixed price service.')
                          // console.log('listAnswers[0]')
                          // console.log(listAnswers[0])
                          servicePrice = listAnswers[0].servicePrice
                          // answer = [...listAnswers]
                          notePersonal = listAnswers[0].notePersonalbreak
                          serviceLegaldescription = listAnswers[0].serviceLegaldescription
                          noteCustomer = listAnswers[0].noteCustomer
                          // We are creating a state machine for this request.
                          myStateMachine = new AnswerStateMachine(listAnswers[0].state, listRequest.isFixedPrice)
                          break
                  default: logger.error('A quotation price service ' + listAnswers.length + ' answer, which is not managed')
                }
              }
              qna = null
              if (listRequest.qna !== null) {
                qna = JSON.parse(listRequest.qna)
              }
              // console.log('myStateMachine')
              // console.log(myStateMachine)
              // Managing the display in case the quotation is on site.
              if (myStateMachine && myStateMachine.getCurrentState() === answerState.onSiteQuotation) {
                servicePrice = formatServicePrice(false, 0)
              }
              // We are checking if there is an appointment associated:
              // this.requests.push({ serviceName: listServices[i].service.name, endUserId: listRequest.endUser.id.substring(29), endUser: listRequest.endUser, qna: qna, createdAt: formatAWSDateTimeIntoDateTime(listRequest.createdAt), datetimeExpiry: formatAWSDateTimeIntoDateTime(listRequest.datetimeExpiry), state: myStateMachine, isFixedPrice: listRequest.isFixedPrice, servicePrice: servicePrice, requestId: listRequest.id, answer: listAnswers[0], serviceLegaldescription: serviceLegaldescription, noteCustomer: noteCustomer, notePersonal: notePersonal, serviceID: listServices[i].service.id, appointmentId: appointmentId, appointmentAnswerId: appointmentAnswerId, appointmentDateTime: appointmentDateTime, appointmentDateTimeISO: appointmentDateTimeISO, resourceID: resourceID, resourceFirstname: resourceFirstname, resourceLastname: resourceLastname, onlineBooking: onlineBooking })
              this.requests.push({ serviceName: listServices[i].service.name, endUserId: listRequest.endUser.id, endUser: listRequest.endUser, qna: qna, createdAt: formatAWSDateTimeIntoDateTime(listRequest.createdAt), datetimeExpiry: formatAWSDateTimeIntoDateTime(listRequest.datetimeExpiry), state: myStateMachine, isFixedPrice: listRequest.isFixedPrice, servicePrice: servicePrice, requestId: listRequest.id, answer: listAnswers[0], serviceLegaldescription: serviceLegaldescription, noteCustomer: noteCustomer, notePersonal: notePersonal, serviceID: listServices[i].service.id, appointmentId: appointmentId, appointmentAnswerId: appointmentAnswerId, appointmentDateTime: appointmentDateTime, appointmentDateTimeISO: appointmentDateTimeISO, resourceID: resourceID, resourceFirstname: resourceFirstname, resourceLastname: resourceLastname, onlineBooking: onlineBooking })
            // INUTILE - }
          // INUTILE - }
        }
      }
    // INUTILE - }
  // console.log('this.requests.......................................')
  // console.log(this.requests)
  } catch (err) {
    logger.error(err)
  }
}

  StopStartSPProposal (myRequestObject) {
    // console.log('myRequestObject', myRequestObject)
    var myRequest
    if (myRequestObject.isProposalSP === true) {
      myRequest = { id: myRequestObject.id, isProposalSP: false, requestCategoryId: myRequestObject.categoryID }
    } else {
      myRequest = { id: myRequestObject.id, isProposalSP: true, requestCategoryId: myRequestObject.categoryID }
    }
    // console.log('myRequest:', myRequest)
    API.graphql({ query: updateRequestMutation, variables: { input: myRequest }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      .then(() => {
        myRequestObject.isProposalSP = myRequest.isProposalSP
        return true
      })
      .catch(err => logger.error(err))
  }

  // The end user is cancelling the request.
  async requestCancelledbyEU (myRequestObject) {
    // console.log('requestCancelledbyEU from Request')
    // console.log('myRequestObject:')
    // console.log(myRequestObject)
    // We are asking the request state manchine to change the state.

    // TODO: to call backend-sm for 'requestCancelledbyEU' transition
    await backEndCancelledByEU({ requestId: myRequestObject.id })
    // TODO: keep this code
    myRequestObject.stateRequest.requestCancelledbyEU()

    // console.log('appointmentDateTimeISO:', myRequestObject.appointmentDateTimeISO)
    var appointmentDateTimeISO = myRequestObject.appointmentDateTimeISO
    var resourceID = myRequestObject.resourceID
    // We check if we need to delete the appointment associated.
    if ((myRequestObject.isFixedPrice) && (myRequestObject.appointmentId !== '')) {
      // We are searching for the index
      var found = false
      var index = 0
      while ((found === false) && (index < this.requests.length)) {
        if ((this.requests[index].id === myRequestObject.id) && (this.requests[index].createdAtOrigin === myRequestObject.createdAtOrigin)) {
          found = true
          // console.log('FOUNNNNNND')
        }
        index++
      }
      if (found) {
        // We delete the associated appointment
        const result = await this.deleteAppointmentExecution(myRequestObject.appointmentId, myRequestObject.appointmentAnswerId, index - 1)
        // console.log(result)
        if (result) {
          // send mail to the resource
          var params = { serviceName: myRequestObject.serviceName, date: DateTime.fromISO(appointmentDateTimeISO).toFormat('yyyy-LL-dd'), time: DateTime.fromISO(appointmentDateTimeISO).toFormat('HH:mm'), resourceId: resourceID, sendTo: 'RE' }
          // console.log('params1:')
          // console.log(params)
          sendEmail('bookingAppointmentDeletedResource', params)
          // send mail to the enduser
          // params.enduserId = myRequestObject.enduserID
          params.enduserId = await getUserSub()
          params.sendTo = 'EU'
          // console.log('params2:')
          // console.log(params)
          sendEmail('bookingAppointmentDeletedResource', params)
        }
      }
    }
    // For each answer state manchine, We are changing the state also.
    // TODO: keep this code below
    for (var i = 0; i < myRequestObject.answers.length; i++) {
      myRequestObject.answers[i].state.requestCancelledbyEU(myRequestObject.answers[i].id)
      // console.log('myRequestObject.answers[i].state')
      // console.log(myRequestObject.answers[i].state)
    }
  }

  async cancelledbySP (myRequestObject) {
    console.log('cancelledbySP in Request.js')
    console.log(myRequestObject.state)
    console.log('myRequestObject')
    // console.log(myRequestObject)
    // We check if we need to delete the appointment associated.
    if ((myRequestObject.isFixedPrice) && (myRequestObject.appointmentId !== '')) {
      // We are searching for the index
      const index = this.findIndex(myRequestObject)
      if (index !== -1) {
        // We delete the associated appointment
        // console.log('We delete the associated appointment:', index)
        // var appointmentDateTime = new Date(myRequestObject.appointmentDateTime)
        const result = await this.deleteAppointmentExecution(myRequestObject.appointmentId, myRequestObject.appointmentAnswerId, index)
        // console.log(result)
        if (result) {
          var params = { serviceName: myRequestObject.serviceName, date: DateTime.fromISO(myRequestObject.appointmentDateTimeISO).toFormat('yyyy-LL-dd'), time: DateTime.fromISO(myRequestObject.appointmentDateTimeISO).toFormat('HH:mm'), resourceId: myRequestObject.resourceID, sendTo: 'RE' }
          sendEmail('bookingAppointmentDeletedResource', params)
          params.enduserId = myRequestObject.endUserId
          params.sendTo = 'EU'
          // console.log('params2:')
          // console.log(params)
          sendEmail('bookingAppointmentDeletedResource', params)
        }
      }
    }

    // We are asking the state manchine to change the state.
    if (myRequestObject.state.cancelledbySP(myRequestObject) === false) {
      logger.error('could not cancel the customer request')
    }
  }

  async createQuotationRequest (endUserId, pickedService, qna, postalCodeId, obj) {
    // console.log('createQuotationRequest')
    // console.log(endUserId)
    // console.log('pickedService:')
    // console.log(pickedService)
    // console.log(pickedService.subCategoryID)
    // console.log('---------------------------------------')
    // console.log('postalCodeId' + postalCodeId)
    var myRequest
    var isProposalSP = false
    // we are searching to check if the end user allow to receive proposal from any SP
    const question = JSON.parse(qna)
    // console.log('question:')
    // console.log(question)
    // console.log(question.questions.length)
    for (var i = 0; i < question.questions.length; i++) {
      if (question.questions[i].tag === 'additional') {
        if (question.questions[i].givenAnswer === obj.$i18n.t('parameters.yes')) {
        // if (question.questions[i].givenAnswer === 'Oui') {
          isProposalSP = true
        }
      }
    }
    // calling backend now
    await backEndcreateQuotationRequest({ serviceId: pickedService.id, qna, postalCodeId, isProposalSP, categoryID: pickedService.categoryID, subCategoryID: pickedService.subCategoryID, requestName: pickedService.name })
    return true
  }

  async createFixedPriceRequest (endUserId, pickedService, appointmentDateTime) {
    // console.log('createFixedPriceRequest')
    // console.log(endUserId)
    // console.log('pickedService')
    // console.log(pickedService)
    // console.log('appointmentDateTime in createFixedPriceRequest in Request')
    // console.log(appointmentDateTime)
    try {
      // console.log('calling backed for createFixedPriceRequest')
      var output
      if (appointmentDateTime && appointmentDateTime.date && appointmentDateTime.time) {
        output = await backEndcreateFixedPriceRequest({ serviceId: pickedService.id, appointmentDate: appointmentDateTime.date, appointmentTime: appointmentDateTime.time })
      } else {
        output = await backEndcreateFixedPriceRequest({ serviceId: pickedService.id, appointmentDate: null, appointmentTime: null })
      }
      // console.log('output - output.answerId', output)
      return output.answerId
    } catch (err) {
      logger.error('createFixedPriceRequest')
      logger.error(err)
      return ''
    }
  }

  // in case a SP is searching for a Service not initially directed to him and find one, so we can attach the Service to the request, so he can manage it after.
  // TODO - this shall be removed as not more needed
  attachServiceRequest (selectedService, editedRequest, myAnswer) {
    // console.log('attachServiceRequest')
    var myDate = new Date().toISOString()
    myDate = myDate.substring(0, 7)
    const spId = getUserSubSync()
    // graphqlV1-->V2: const myServiceRequest = { serviceRequestsRequestId: editedRequest.requestId, serviceRequestsServiceId: selectedService, serviceProviderID: spId, createdAtShort: myDate }
    const myServiceRequest = { requestServicesId: editedRequest.requestId, serviceRequestsId: selectedService, serviceProviderID: spId, createdAtShort: myDate }

    // console.log('myServiceRequest')
    // console.log(myServiceRequest)
    API.graphql({ query: createServiceRequestsMutation, variables: { input: myServiceRequest }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
    .then(async (apiData) => {
      // console.log('create ServiceRequests OK, send email to enduser')
      _sendEmail('answerSPQuotationBased', { enduserId: editedRequest.endUserId, servicePrice: myAnswer.servicePrice, serviceName: editedRequest.serviceName, serviceProviderId: spId, sendTo: 'EU' })
      return true
    })
    .catch(err => {
      logger.error('attachServiceRequest - create ServiceRequests')
      logger.error(err)
      return false
    })
    return false
  }

  async onSiteQuotationRequest (requestPicked, index) {
    try {
      // console.log('this.requests before')
      // console.log(this.requests)
      // console.log('requestPicked:')
      // console.log(requestPicked)
      // console.log('index:')
      // console.log(index)
      // We need to create an empty answer linked to this request.
      // var myAnswer = { answerServiceProviderId: await getUserSub(), answerRequestId: requestPicked.requestId, servicePrice: 0, datetimeExpiry: '1900-01-01T00:00:00.000Z', noteCustomer: '', notePersonal: '', serviceLegaldescription: '', quotation: '', state: answerState.onSiteQuotation }
      // graphqlV1-->V2 var myAnswer = { answerServiceProviderId: await getUserSub(), answerRequestId: requestPicked.requestId, servicePrice: 0, datetimeExpiry: provideExpirationDate(), noteCustomer: '', notePersonal: '', serviceLegaldescription: '', quotation: '', state: answerState.onSiteQuotation }
      // calling backend now
      var myAnswer = { serviceProviderAnswersId: await getUserSub(), requestAnswersId: requestPicked.requestId, servicePrice: 0, datetimeExpiry: provideExpirationDate(), noteCustomer: '', notePersonal: '', serviceLegaldescription: '', quotation: '', state: answerState.onSiteQuotation }
      const newAnswer = await backEndOnSiteQuotationRequest({ requestId: requestPicked.requestId })

      if (this.requests[index].state.getType() === 'AnswerStateMachine') {
        this.requests[index].state.onSiteRequest()
      }
      myAnswer.id = newAnswer.answerId
      this.requests[index].answer = Object.assign({}, myAnswer)
      // We change the state for the display ???
      // requestPicked.state = undefined
      return true
    } catch (err) {
      logger.error(err)
      return false
    }
  }

  async createAnswerForRequest (requestPicked, myAnswerData, index) {
    try {
      // console.log('this.requests before')
      // console.log(this.requests)
      // console.log('requestPicked:')
      // console.log(requestPicked)
      // console.log('index:')
      // console.log(index)
      const spId = await getUserSub()
      // TODO -- this block shall be replaced by 'upsertAnswer' transition
      if (requestPicked.answer === undefined) {
        // console.log('requestPicked.answer === undefined')
        // We need to create the answer
        // graphqlV1-->V2: var myAnswer = { answerServiceProviderId: spId, answerRequestId: requestPicked.requestId, servicePrice: myAnswerData.servicePrice, datetimeExpiry: myAnswerData.dateExpiry + 'T23:59:00.000Z', noteCustomer: myAnswerData.noteCustomer, notePersonal: myAnswerData.notePersonal, serviceLegaldescription: myAnswerData.serviceLegaldescription, quotation: '', state: answerState.quoted }
        var myAnswer = { serviceProviderAnswersId: spId, requestAnswersId: requestPicked.requestId, servicePrice: myAnswerData.servicePrice, datetimeExpiry: myAnswerData.dateExpiry + 'T23:59:00.000Z', noteCustomer: myAnswerData.noteCustomer, notePersonal: myAnswerData.notePersonal, serviceLegaldescription: myAnswerData.serviceLegaldescription, quotation: '', state: answerState.quoted }
        const apiData = await upsertAnswer({ requestId: requestPicked.requestId, serviceId: requestPicked.serviceID, servicePrice: myAnswerData.servicePrice, noteCustomer: myAnswerData.noteCustomer, notePersonal: myAnswerData.notePersonal, serviceLegaldescription: myAnswerData.serviceLegaldescription })
        const answerId = apiData.answerId
        const filename = await updateQuotationFile(myAnswerData, requestPicked.requestId, answerId)
        // TODO: - very inefficient  because we do update just for the sake of updating the filename
        // TODO: - need to put logic for the filename generation on lambda instead?
        await upsertAnswer({ answerId, requestId: requestPicked.requestId, serviceId: requestPicked.serviceID, servicePrice: myAnswerData.servicePrice, noteCustomer: myAnswerData.noteCustomer, notePersonal: myAnswerData.notePersonal, serviceLegaldescription: myAnswerData.serviceLegaldescription, filename, skipEmail: true })

        myAnswer.state = apiData.state
        // console.log('myAnswer')
        // console.log(myAnswer)
        // console.log('create answer - apiData:')
        // console.log(apiData)
        // We update the quotation file in the cloud
        if (this.requests[index].state.getType() === 'AnswerStateMachine') {
          // console.log('State type = AnswerStateMachine --')
          // We will need to change state for the request
          this.requests[index].state.makeQuotation(requestPicked, answerId)
          // We change the state for the display
          // requestPicked.state = this.requests[index].state.currentState // THIS LINE IS TO CHECK should be a NEW... new AnswerStateMachine(item.state.currentState, item.isFixedPrice)
          this.requests[index].servicePrice = myAnswerData.servicePrice
          myAnswer.id = answerId// apiData.data.createAnswer.id
          if (filename !== 'error') {
          myAnswer.quotation = filename
          }
          this.requests[index].answer = Object.assign({}, myAnswer)
          // We change the state for the display
          // requestPicked.state = this.requests[index].state.currentState // THIS LINE IS TO CHECK should be a NEW or undefined... new AnswerStateMachine(item.state.currentState, item.isFixedPrice)
          requestPicked.state = undefined
        }

        // console.log('requestPicked after:')
        // console.log(requestPicked)
        // console.log('this.requests after')
        // console.log(this.requests)
        // console.log('send email to enduser.')
        // TODO - orig _sendEmail('answerSPQuotationBased', { enduserId: requestPicked.endUserId, servicePrice: myAnswerData.servicePrice, serviceName: requestPicked.serviceName, serviceProviderId: spId, sendTo: 'EU' })
        // console.log('send email to enduser finished.')
        return true
      } else {
        // We need to update the quotation - We assume we have only 1 answer.
        // TODO - need yet tested; which page that will run this code below?
        // console.log('the answer is updated.')
        var myAnswer1
        if ((requestPicked.answer.state === answerState.quoteRefusedbyEU) || (requestPicked.answer.state === answerState.onSiteQuotation)) {
          myAnswer1 = { id: requestPicked.answer.id, servicePrice: myAnswerData.servicePrice, datetimeExpiry: myAnswerData.dateExpiry + 'T23:59:00.000Z', noteCustomer: myAnswerData.noteCustomer, notePersonal: myAnswerData.notePersonal, serviceLegaldescription: myAnswerData.serviceLegaldescription, state: answerState.quoted }
        } else {
          myAnswer1 = { id: requestPicked.answer.id, servicePrice: myAnswerData.servicePrice, datetimeExpiry: myAnswerData.dateExpiry + 'T23:59:00.000Z', noteCustomer: myAnswerData.noteCustomer, notePersonal: myAnswerData.notePersonal, serviceLegaldescription: myAnswerData.serviceLegaldescription }
        }
        // console.log('myAnswer1:')
        // console.log(myAnswer1)
        const filename = await updateQuotationFile(myAnswerData, requestPicked.requestId, requestPicked.answer.id, requestPicked.answer.quotation)
        const apiData = await upsertAnswer({ answerId: requestPicked.answer.id, requestId: requestPicked.requestId, serviceId: requestPicked.serviceID, servicePrice: myAnswerData.servicePrice, noteCustomer: myAnswerData.noteCustomer, notePersonal: myAnswerData.notePersonal, serviceLegaldescription: myAnswerData.serviceLegaldescription, filename })
        myAnswer1.state = apiData.state
        requestPicked.answer.noteCustomer = myAnswerData.noteCustomer
        requestPicked.answer.notePersonal = myAnswerData.notePersonal
        requestPicked.answer.serviceLegaldescription = myAnswerData.serviceLegaldescription
        requestPicked.answer.servicePrice = myAnswerData.servicePrice
        this.requests[index].servicePrice = myAnswerData.servicePrice
        requestPicked.answer.dateExpiry = myAnswerData.dateExpiry
        requestPicked.answer.datetimeExpiry = myAnswerData.dateExpiry + 'T23:59:00.000Z'
        if (filename !== 'error') {
          requestPicked.answer.quotation = filename
        }
        // console.log(this.requests)
        if ((requestPicked.answer.state === answerState.quoteRefusedbyEU) || (requestPicked.answer.state === answerState.onSiteQuotation)) {
          requestPicked.answer.state = answerState.quoted
          this.requests[index].state.currentState = answerState.quoted
        }

        return true
      }
    } catch (err) {
      logger.error(err)
    }
  }

    // This function is trigered by the Booking component, once it is clicked on validate.
  // It is used to refresh the answer with the booking detail.
  // The argument is the answerId, where the appointment data are contained.
  async refreshAppointmentDateAnswer (answerID) {
    try {
      // console.log('refreshAppointmentDateAnswer')
      // console.log(answerID)
      // console.log(this.requests)
      var found = false
      var i = 0
      while ((found === false) && (i < this.requests.length)) {
        // console.log(this.requests[i])
        // the format is coming from eu or sp the list of request is a little differently organised.
        // console.log('this.requests[i]', i)
        // console.log(this.requests[i])
        // console.log(Array.isArray(this.requests[i].answers))
        if (Array.isArray(this.requests[i].answers)) {
          // this is coming from eu side.
          // console.log('We pass in array side', i)
          if (this.requests[i].answers[0].id === answerID) {
            // console.log('FOUND in array', i)
            found = true
          }
        } else {
          // This is coming from SP side
          // console.log('We pass in object side', i)
          if (this.requests[i].answer !== undefined) {
            // console.log('answer is from object:', this.requests[i].answer.id)
            if (this.requests[i].answer.id === answerID) {
              // console.log('FOUND in object', i)
              found = true
            }
          }
        }
        i++
      }
      if (found) {
        const apiData = await API.graphql({ query: getAnswerAppointmentDetails, variables: { id: answerID, limit: 4000 }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        // console.log(apiData)
        this.requests[i - 1].appointmentAnswerId = apiData.data.getAnswer.appointments.items[0].id
        this.requests[i - 1].appointmentId = apiData.data.getAnswer.appointments.items[0].appointment.id
        this.requests[i - 1].appointmentDateTime = DateTime.fromISO(apiData.data.getAnswer.appointments.items[0].appointment.dateTime).setLocale(i18n.locale).toFormat('yyyy-LLL-dd HH:mm')
        this.requests[i - 1].appointmentDateTimeISO = apiData.data.getAnswer.appointments.items[0].appointment.dateTime
        this.requests[i - 1].resourceFirstname = apiData.data.getAnswer.appointments.items[0].appointment.resourceFirstname
        this.requests[i - 1].resourceLastname = apiData.data.getAnswer.appointments.items[0].appointment.resourceLastname
        this.requests[i - 1].resourceID = apiData.data.getAnswer.appointments.items[0].appointment.resourceID
      }
    } catch (err) {
      logger.error(err)
    }
  }

  async deleteAppointmentExecution (appointmentId, appointmentAnswerId, index) {
    try {
      // console.log('deleteAppointmentExecution')
      await deleteAppointment(appointmentId, appointmentAnswerId)
      // We clean the request:
      // console.log('index in request:', index)
      // console.log('this.requests before delete data')
      // console.log(this.requests)
      this.requests[index].appointmentAnswerId = ''
      this.requests[index].appointmentId = ''
      this.requests[index].appointmentDateTime = ''
      this.requests[index].resourceFirstname = ''
      this.requests[index].resourceLastname = ''
      return true
    } catch (err) {
      logger.error(err)
      return false
    }
  }

  async deleteAppointment (myRequest, index) {
    // console.log('deleteAppointment from request: ', index)
    // console.log(myRequest)
    // console.log(this.requests)
    // console.log('answer id:', this.requests[index].answers[0].id)
    if (myRequest.answers[0].id === this.requests[index].answers[0].id) {
      return this.deleteAppointmentExecution(this.requests[index].appointmentId, this.requests[index].appointmentAnswerId, index)
    }
  }

  // find the index of the request passed in parameter. Return -1 if not found. If found the index number.
  findIndex (myRequest) {
    var found = false
    var index = 0
    while ((found === false) && (index < this.requests.length)) {
      if (this.requests[index].requestId === myRequest.requestId) {
        found = true
      }
      index++
    }
    if (found) {
      return (index - 1)
    }
    return -1
  }
}
