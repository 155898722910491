<!------------------------------------------------------------------------------
// KIDIWI Digital property
// XXXXXXXXXX
// Props:
// Example of usage:
      <miniweb-template-basic />
// ------------------------------------------------------------------------------>
<template>
  <div
    v-if="displayComponent()"
  >
    BASIC TEMPLATE<br>
    <v-card>
      <v-card-title> Basic template is active</v-card-title>
    </v-card>
    <v-row>
      MiniWeb Page Basic<br>
      {{ dataTemplate }}
    </v-row>
  </div>
</template>

<script>
  // Extensions
  // import View from '@/views/View'

  // Mixins
  // import LoadSections from '@/mixins/load-sections'
  // import { getMiniwebData } from '@/services/miniweb/service'
  import { TEMPLATE_MINIWEB_LIST } from '@/services/miniweb/resolver'
  // import { isObjectEmpty } from '@/helpers/GenericFunctions'

  export default {
    name: 'BasicTemplate',
    // extends: View,
    // Contains the mixins.... + call getService to get the data
    /*
    mixins: [
      LoadSections([
        'sections/404', // To be replace with something
        // 'sections/contact-us',
        // 'sections/info',
      ]),
    ],
    */
    metaInfo: { title: 'This is basic template' },
    props: {
      dataTemplate: {
        type: Object,
      },
      /*
      searchKey: {
        type: String,
        default: '',
      },
      */
    },
    data: () => ({
      // miniwebData: {},
    }),
    created: function () {
      /*
      console.log('created of Basic')
      this.miniwebData = getMiniwebData(this.searchKey)
      getMiniwebData(this.searchKey)
        .then((data) => {
          this.miniwebData = data
          console.log('miniwebData')
          console.log(this.miniwebData)
        })
      */
    },
    methods: {
      displayComponent () {
        // if (!isObjectEmpty(this.dataTemplate)) return true
        if (this.dataTemplate && this.dataTemplate.template && this.dataTemplate.template.name === TEMPLATE_MINIWEB_LIST[0].name) return true
        return false
      },
    },
  }
</script>
