<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to select/delete a picture and store it into the S3 bucket
// v-model: object with the image info {
      name: '',            // name of the file
      image: null,         // pointer on the image/picture object created by the system
    }
// Props:
//  - pictureSize: size of the picture (square) - Optional default 100
//  - nature: nature of the picture to pic. Could be: Logo, Photo or Picture - Optional, default Logo
//  - display: this is a trick to replace key & to force the update of the input value. It is mandatory. This value is a counter to increase. It is used when the value is updated by the parent.
//  - imageNameComplement: additional information that can be used to find make a difference in the name in the S3 bucket
//  - pictureSelectorId: Id for the file selector. SHould be unique per page. Default value is logo_file_input
//  - fileInputHint: small sentence to give a hint to the user. Default is empty string.
// Example of usage:
      <kdw-picture-selector
        v-model="mypicture"
        :display="trickUpdatePicture"
        image-name-complement="miniWeb"
      />
// ------------------------------------------------------------------------------>
<template>
  <v-container
    fluid
  >
    <v-row
      align="center"
      align-content="center"
      justify="center"
    >
      <base-img
        v-if="url"
        id="logo_display"
        class="rounded ml-5"
        :max-width="pictureSize"
        :max-height="pictureSize"
        :src="url"
        alt="Logo"
      />
      <base-img
        v-if="url === null"
        id="logo_display"
        class="rounded ml-5"
        :max-width="pictureSize"
        :max-height="pictureSize"
        :src="require('@/assets/Avatar-02.svg')"
        alt="LogoDefault"
        @click="triggerClickOnFileInput()"
      />
    </v-row>
    <v-row
      align="center"
      align-content="space-around"
      justify="center"
    >
      <v-col
        cols="12"
        sm="10"
        class="d-flex justify-center align-center"
      >
        <v-file-input
          v-if="!url"
          :id="pictureSelectorId"
          v-model="image"
          :rules="rules"
          accept="image/png, image/jpeg, image/bmp"
          :placeholder="$t(selectText)"
          prepend-icon="mdi-paperclip"
          dense
          show-size
          :hint="fileInputHint"
          @change="previewImage()"
        />
        <v-btn
          v-if="url"
          dense
          plain
          outlined
          color="jean"
          min-width="144"
          class="font-weight-bold ml-7"
          rounded
          @click="deleteImage()"
        >
          {{ $t(deleteText) }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // import { Logger } from 'aws-amplify'
  import { FILEHEADERLOGO, FILEHEADERPICTURERESSOURCE, FILEHEADERPICTUREIMAGE } from '@/services/File'
  import { getUserSub } from '@/services/User'
  import { getPictureUrlfromS3Bucket } from '@/helpers/GenericFunctionsS3Download'

  // const logger = new Logger('PictureSelector')
  const LOGO = 'Logo'
  const PHOTO = 'Photo'
  const PICTURE = 'Picture'

  export default {
    name: 'PictureSelector',

    props: {
      value: {
        type: Object,
        required: true,
      },
      pictureSize: {
        type: String,
        default: '100',
      },
      nature: {
        type: String,
        default: PICTURE,
      },
      display: {
        type: Number,
        default: 0,
        required: true,
      },
      imageNameComplement: {
        type: String,
        default: '',
      },
      pictureSelectorId: {
        type: String,
        default: 'logo_file_input',
      },
      fileInputHint: {
        type: String,
        default: '',
      },
    },
    data: () => ({
      url: null, // Url of the image leading to S3 bucket
      name: '', // Name of the selected image
      image: null, // Pointer onto the image object selected by the enduser
      rules: [
        value => !value || value.size < 2000000 || this.$i18n.t('AccountSP.sizeLogo'),
      ],
      selectText: 'AccountSP.pickLogo',
      deleteText: 'button.deleteLogo',
      headerName: FILEHEADERLOGO,
    }),
    watch: {
      display: function (val) {
        this.getPicturefromS3Bucket()
      },
    },
    created () {
      // We are intializing the display based on the nature
      if (this.nature === LOGO) {
        this.selectText = 'AccountSP.pickLogo'
        this.deleteText = 'button.deleteLogo'
        this.headerName = FILEHEADERLOGO + this.imageNameComplement
      } else {
        if (this.nature === PHOTO) {
          this.selectText = 'AccountSP.pickPhoto'
          this.deleteText = 'button.deletePhoto'
          this.headerName = FILEHEADERPICTURERESSOURCE + this.imageNameComplement
        } else {
          this.selectText = 'AccountSP.pickPicture'
          this.deleteText = 'button.deletePicture'
          this.headerName = FILEHEADERPICTUREIMAGE + this.imageNameComplement
        }
      }
      this.getPicturefromS3Bucket()
    },
    methods: {
      triggerClickOnFileInput () {
        var fileUpload = document.getElementById(this.pictureSelectorId)
        if (fileUpload != null) {
          fileUpload.click()
        }
      },
      getPicturefromS3Bucket () {
        // We collect the picture coming from the v-model and we initialise it.
        if (this.value && this.value.name) {
          this.name = this.value.name
          getPictureUrlfromS3Bucket(this.name)
            .then((url) => {
              this.url = url
            })
        }
      },
      emitResult () {
        // console.log('value:')
        // console.log(this.value)
        // console.log('this.value.originalName:')
        // console.log(this.value.originalName)
        var pictureObject = {}
        pictureObject.originalName = this.value.originalName
        if (this.name) {
          pictureObject.name = this.name
        } else pictureObject.name = ''
        if (this.image) {
          pictureObject.image = this.image
        } else pictureObject.image = null
        // console.log('pictureObject:')
        // console.log(pictureObject)
        this.$emit('input', pictureObject)
      },
      previewImage () {
        this.url = URL.createObjectURL(this.image)
        // console.log(this.image)
        // console.log(this.url)
        getUserSub()
          .then((usersub) => {
            this.name = usersub + this.headerName + this.image.name
            this.emitResult()
          })
      },
      deleteImage () {
        // console.log('delete logo click')
        this.url = null
        this.image = null
        this.name = ''
        this.emitResult()
      },
    },
  }
</script>
