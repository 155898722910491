// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the list of ressources
// ------------------------------------------------------------------------------

import { API, Logger } from 'aws-amplify'
import { ANONYMOUS, ENDUSER, SERVICEPROVIDER, getUserType, getUserSub } from '@/services/User'
import { getServiceProviderListRessourcesID, getServiceRessourceDataList } from '@/graphql/kdwqueries'
import { Resource } from '@/services/Resource'
// import { doGqlWithAwsLambdaAuthorizer } from '@/services/commonTools'

const logger = new Logger('Resource list')

export class ResourceList {
  list = []

  constructor () {
    logger.info('Ressource list created.')
  }

  copy (resourceList) {
    // console.log('This is a copy function of the ressource List')
    // console.log(resourceList)
    var newResource
    for (var i = 0; i < resourceList.list.length; i++) {
      newResource = new Resource()
      newResource.copy(resourceList.list[i])
      this.list.push(newResource)
    }
  }

  // this function is loading a ressource from the DB for a specific service provider
  async loadResourcesSP () {
    // console.log('load Ressource for SP')
    try {
      const apiData = await API.graphql({ query: getServiceProviderListRessourcesID, variables: { id: await getUserSub() }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      // console.log(apiData)
      const serviceProvider = apiData.data.getServiceProvider.resources.items
      // console.log('serviceProvider....')
      // console.log(serviceProvider)
      if (serviceProvider.length === 0) {
        return
      }
      var newRessource
      for (var i = 0; i < serviceProvider.length; i++) {
        newRessource = new Resource()
        await newRessource.loadResource(serviceProvider[i].id)
        // console.log('newRessource: ')
        // console.log(newRessource)
        this.list.push(newRessource)
      }
      // console.log(this.list)
    } catch (err) {
      logger.error(err)
      // alert(this.i18n.t('ressources.alertFailCreateRes'))
    }
  }

  // this function is adding a ressource with a specific ressource ID
  async addResourceFromId (id) {
    // console.log('load Ressource for SP')
    try {
      // const apiData = await API.graphql({ query: getServiceProviderListRessourcesID, variables: { id: await getUserSub() }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      // console.log(apiData)
      // const serviceProvider = apiData.data.getServiceProvider.resources.items
      // console.log(serviceProvider)
      if ((id === undefined) || (id === null)) {
        return
      }
      var newRessource
      newRessource = new Resource()
      await newRessource.loadResource(id)
      // console.log(newRessource)
      this.list.push(newRessource)
      // console.log(this.list)
    } catch (err) {
      logger.error(err)
      // alert(this.i18n.t('ressources.alertFailCreateRes'))
    }
  }

  // We empty the list of ressource.
  reset () {
    this.list.splice(0, this.list.length)
  }

  // this function is loading a ressource from the DB for a specific service that we can book online
  async loadResourcesServiceCanBookOnline (serviceID) {
    // console.log('load Ressource for specific service')
    try {
      // console.log('try to load for loadResourcesServiceCanBookOnline', serviceID)
      const userType = await getUserType()
      // console.log('userType', userType)
      // We empty the list
      this.reset()
      var apiData
      // NEED TO CHECK a better logic:
      // obj.userType !== obj.ENDUSER
      if (userType === ANONYMOUS) {
        // We are not logged
        apiData = await API.graphql({ query: getServiceRessourceDataList, variables: { id: serviceID }, authMode: 'AWS_IAM' })
      }
      if (userType === ENDUSER || userType === SERVICEPROVIDER) {
        // We are already logged
        apiData = await API.graphql({ query: getServiceRessourceDataList, variables: { id: serviceID }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      }
      /*
      if (userType === SERVICEPROVIDER) {
        // We are handling this case before (before pickItem) to prevent it, so normally we should never pass here!!!
        console.log('CAse we believe we should not pass in!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
        return
      }
      */
      // console.log('apiData for loadResourcesServiceCanBookOnline')
      // console.log(apiData)
      const service = apiData.data.getService.resources.items
      // console.log('service for loadResourcesServiceCanBookOnline')
      // console.log(service)
      if (service.length === 0) {
        return
      }
      var newRessource
      for (var i = 0; i < service.length; i++) {
        if (service[i].resource.canBookOnline) {
          newRessource = new Resource()
          // console.log('service Id for loadResourcesServiceCanBookOnline', service[i].resource.id)
          await newRessource.loadResourceForBooking(service[i].resource.id, userType)
          // console.log('loadResourceForBooking is finished & pushing the new ressource')
          this.list.push(newRessource)
        }
      }
      // console.log('this.list')
      // console.log(this.list)
    } catch (err) {
      logger.error(err)
      // alert(this.i18n.t('ressources.alertFailCreateRes'))
    }
  }

  // this function is loading a ressource from the DB for a specific service provider to build the calendar Data
  async loadResourcesCalendar () {
    // console.log('load Ressource for Calendar')
    try {
      const apiData = await API.graphql({ query: getServiceProviderListRessourcesID, variables: { id: await getUserSub() }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      // console.log(apiData)
      const serviceProvider = apiData.data.getServiceProvider.resources.items
      // console.log('serviceProvider:')
      // console.log(serviceProvider)
      if (serviceProvider.length === 0) {
        return
      }
      var newRessource
      for (var i = 0; i < serviceProvider.length; i++) {
        newRessource = new Resource(serviceProvider[i].id)
        // await newRessource.loadResource(serviceProvider[i].id)
        // console.log('newRessource')
        // console.log(newRessource)
        this.list.push(newRessource)
      }
      // console.log('this.list')
      // console.log(this.list)
    } catch (err) {
      logger.error(err)
      // alert(this.i18n.t('ressources.alertFailCreateRes'))
    }
  }

  addResource (resource) {
    this.list.push(resource)
  }

  async updateResource (resource, index) {
    // console.log('updateResource from RList')
    if ((index > this.list.length) || (index < 0)) {
      return false
    }
    return await this.list[index].updateResource(resource)
  }

  async deleteResource (index) {
    // console.log('deleteResource from RList')
    if ((index > this.list.length) || (index < 0)) {
      return false
    }
    // We delete this resource from the DB
    const result = await this.list[index].deleteResource()
    if (result === false) {
      return false
    }
    // We remove it from the list
    this.list.splice(index, 1)
    return true
  }

  async buildEventForAListOfRessource (resList, newEventList) {
    // console.log('resList')
    // console.log(resList)
    // We build the new event list
    for (var i = 0; i < resList.length; i++) {
      // console.log(this.resourceList[i])
      await resList[i].loadResourceForCalendar(resList[i].id, newEventList)
    }
  }
}
