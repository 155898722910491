// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the Request & answer state machine
// ------------------------------------------------------------------------------

import { API, Logger, Storage } from 'aws-amplify'
import { getUserSub } from '@/services/User'
import { updateRequest as updateRequestMutation, updateAnswer as updateAnswerMutation, sendEmailGQL } from '@/graphql/mutations'
import { cancelledBySP as backEndCancelledBySP, refusedQuoteByEU as backEndRefusedByEU, acceptQuoteByEU as backEndAcceptQuoteByEU } from '@/services/stateMachineBackend'
import i18n from '@/plugins/i18n.js'

const logger = new Logger('StateMachine')

// Info: https://www.sohamkamani.com/javascript/enums/
/* NEED TO REVISE THE DEFININTION WITH THE LATEST LIST
# this is define the different states of a request from the END USER TO THE SERVICE PROVIDER
# started: the end user have defined some criteria but not issued the request
# requested: the end user have issued the request to the service provider
# bookingQuotation: the SP requests to have a booking appointment to make a quotation. Managed by a sub-state machine : BookingState
# quotation: the SPs at least one quotation. This is managed by a dedicated state machine
# vouchers: the end user have received some vouchers to decrease the cost of the service to be provided by the service provider. Managed by a sub-state machine :
# bookingJob: the end user requests to have an appointement for the service to be delivered
# contractValidated: the SP and EU have both agreed on the terms and conditions
# refusedSP: the SP have refused the request proposed by the EU
# cancelledEU: The end user is cancelling the request
# cancelledSP: The SP is cancelling the proposal
# expired: The end user request is expired (expiration date is provided by EU)
# accompte: this is the management of the partial payment. Managed by a sub-state machine: TBD
# jobExecution: this is the management of the job corresponding to the request. Managed by a sub-state machine: JobState
# payment: this is the management of the paiement corresponding to the request. Managed by a sub-state machine: PaymentState
# fidelity: this is the management of the fidelity corresponding to the request. Managed by a sub-state machine: TBD
# closed: The request is succesfully executed
*/
export const requestState = {
    started: 'STARTED',
    validated: 'VALIDATED',
    cancelledbyEU: 'CANCELLED_BY_EU',
    expired: 'EXPIRED',
/*
    started: 'STARTED',
    // requested: 'REQUESTED',
    bookingQuotation: 'BOOKING_QUOTATION',
    pendingQuotation: 'PENDING_QUOTATION',
    quoted: 'QUOTED',
    contractValidated: 'CONTRACT_VALIDATED',
    contractCancelledbyEU: 'CONTRACT_CANCELLED_BY_EU',
    contractCancelledbySP: 'CONTRACT_CANCELLED_BY_SP',
    contractExpired: 'CONTRACT_EXPIRED',
    accompteBillingAddressSet: 'ACCOMPTE_BILLING_ADDRESS_SET',
    accompteInvoiced: 'ACCOMPTE_INVOICED',
    accomptePayed: 'ACCOMPTE_PAYED',
    accompteReceipt: 'ACCOMPTE_RECEIPT',
    bookingJob: 'BOOKING_JOB',
    jobExecutionReadyToStart: 'JOB_EXECUTION_READY_TO_START',
    jobExecutionFinished: 'JOB_EXECUTION_FINISHEDT',
    paymentBillingAddressSet: 'PAYMENT_BILLING_ADDRESS_SET',
    paymentInvoiced: 'PAYMENT_INVOICED',
    paymentPayed: 'PAYMENT_PAID',
    paymentReceipt: 'PAYMENT_RECEIPT',
    vouchers: 'VOUCHERS',
    closed: 'CLOSED',
*/
   }

// const state = {
//     "BOOK": {
//        "BOOKED",
//        "RDV DONE",
//    },
// }

export class RequestStateMachine {
    previousState = null
    currentState = null
    isFixedPrice = null
    // probably the listener will not be used.
    stateListeners = []

    constructor (state, isFixedPrice) {
        // logger.info('RequestStateMachine created.')
        if (state) {
          this.setState(state)
          this.isFixedPrice = isFixedPrice
        }
        return this.currentState
      }

    // If we want to be notified that a state change happened, we are stacking a function to make a call back. Not used in our case so far.
    onStateChange (func) {
        this.stateListeners.push(func)
    }

    // this is to define the state change.
    changeState (state) {
        this.previousState = this.currentState
        this.currentState = state
        // Probably this listner will not be used
        for (var l of this.stateListeners) {
            l(this.currentState)
        }
        return true
    }

    getType () {
        return 'RequestStateMachine'
    }

    getCurrentState () {
        return this.currentState
    }

    getCurrentStateTranslated () {
        switch (this.currentState) {
            case 'STARTED': return i18n.t('stateMachineLib.request_STARTED')
            case 'VALIDATED': return i18n.t('stateMachineLib.request_VALIDATED')
            case 'CANCELLED_BY_EU': return i18n.t('stateMachineLib.request_CANCELLED_BY_EU')
            case 'EXPIRED': return i18n.t('stateMachineLib.request_EXPIRED')
        }
    }

    getPreviousState () {
        return this.previousState
    }

    /*
    onBookingSelected () {
        if (currentState in ['REQUESTED', '??']) {
            return changeState ("BOOKING STARTED")
        } else {
            return "KO"
        }
    }

    onPayment () {
        if (currentState in ['REQUESTED', '??']) {
            this.previousState = thsi.currentState
            this.currentState = "BOOKING STARTED"
            return "OK"
        } else {
            return "KO"
        }
    }

    */
    isDisabled () {
        if ((this.currentState === requestState.cancelledbyEU) || (this.currentState === requestState.expired)) {
            return true
        }
        return false
    }

    // The Service provider is cancelling the request
    cancelledbySP (myRequestObject) {
        console.log('cancelledbySP-requestStateMachine')
        // console.log(myRequestObject)
        // console.log(this.isFixedPrice)
        // if (myRequestObject.isFixedPrice === true) {
        if ((this.currentState === requestState.started) || (this.currentState === requestState.bookingQuotation) || (this.currentState === requestState.pendingQuotation) || (this.currentState === requestState.quoted)) {
                // We will write the new state in the DB
                const myRequest = { id: myRequestObject.requestId, state: requestState.contractCancelledbySP }
                API.graphql({ query: updateRequestMutation, variables: { input: myRequest }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
                  .then(apiData => {
                    // console.log('The cancellation state in DB happened ........................')
                    if (myRequestObject.isFixedPrice === false) {
                        // console.log('We check if we need to delete the file  attached ........................')
                        if (myRequestObject.answer !== undefined) {
                            if (myRequestObject.answer.quotation !== null) {
                            Storage.remove(myRequestObject.answer.quotation)
                            // console.log('The file delete happened ........................')
                            }
                        }
                    }
                    logger.info('Delete Request by Service Provider ok for:' + myRequestObject.requestId)
                    return this.changeState(requestState.contractCancelledbySP)
                  })
                  .catch(err => {
                    logger.error(err)
                    return false
                  })
            } else {
                // console.log('state in cancelledbyEU not found')
                return false
            }
        // } else {
            // TO BE DONE
        // }
    }

    // TESTED & VALIDATED
    // The end user is planning to stop the contract from any state
    // TODO: this will need update see below
    requestCancelledbyEU () {
        console.log('cancelledbyEU from state request machine')
        // console.log(this.currentState)
        // console.log(myRequestObject)
        return this.changeState(requestState.cancelledbyEU)
    }

    setState (state) {
        if (state) {
            var found = false
            switch (state) {
                case requestState.started:
                  found = true; break
                case requestState.validated:
                  found = true; break
                case requestState.cancelledbyEU:
                  found = true; break
                case requestState.expired:
                  found = true; break
            }
          if (found === true) {
            this.currentState = state
          }
          return this.currentState
      }
    }
}

// -----------------------------------------------------------------------------------------------------------------------
// -------------------------------- AnswerStateMachine -------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------------

export const answerState = {
    pendingQuotation: 'PENDING_QUOTATION',
    onSiteQuotation: 'ONSITE_QUOTATION',
    quoted: 'QUOTED',
    contractValidated: 'CONTRACT_VALIDATED',
    quoteExpired: 'QUOTE_EXPIRED',
    quoteRefusedbyEU: 'QUOTE_REFUSED_BY_EU',
    quoteCancelledbySP: 'QUOTE_CANCELLED_BY_SP',
    refuseToQuote: 'REFUSE_TO_QUOTE_BY_SP',
    contractCancelledbyEU: 'CONTRACT_CANCELLED_BY_EU',
    contractCancelledbySP: 'CONTRACT_CANCELLED_BY_SP',
    requestCancelledbyEU: 'REQUEST_CANCELLED_BY_EU', // it's here for stat purpose.
    requestExpired: 'REQUEST_EXPIRED',
   }

export class AnswerStateMachine {
    previousState = null
    currentState = null
    isFixedPrice = null
    // probably the listener will not be used.
    stateListeners = []

    constructor (state, isFixedPrice) {
        // logger.info('RequestStateMachine created.')
        if (state) {
          this.setState(state)
          this.isFixedPrice = isFixedPrice
        }
        return this.currentState
      }

    // If we want to be notified that a state change happened, we are stacking a function to make a call back. Not used in our case so far.
    onStateChange (func) {
        this.stateListeners.push(func)
    }

    // this is to define the state change.
    changeState (state) {
        this.previousState = this.currentState
        this.currentState = state
        // Probably this listner will not be used
        for (var l of this.stateListeners) {
            l(this.currentState)
        }
        return true
    }

    getType () {
        return 'AnswerStateMachine'
    }

    getCurrentState () {
        return this.currentState
    }

    getPreviousState () {
        return this.previousState
    }

    setState (state) {
        if (state) {
            var found = false
            switch (state) {
                case answerState.pendingQuotation:
                    found = true; break
                case answerState.onSiteQuotation:
                    found = true; break
                case answerState.quoted:
                    found = true; break
                case answerState.contractValidated:
                    found = true; break
                case answerState.quoteExpired:
                    found = true; break
                case answerState.quoteRefusedbyEU:
                    found = true; break
                case answerState.quoteCancelledbySP:
                    found = true; break
                case answerState.refuseToQuote:
                    found = true; break
                case answerState.contractCancelledbyEU:
                    found = true; break
                case answerState.contractCancelledbySP:
                    found = true; break
                case answerState.requestCancelledbyEU:
                    found = true; break
                case answerState.requestExpired:
                    found = true; break
            }
          if (found === true) {
            this.currentState = state
          }
          return this.currentState
      }
    }

    isDisabled () {
        // console.log('isDisabled:' + indicator)
        if ((this.currentState === answerState.quoteExpired) || (this.currentState === answerState.quoteCancelledbySP) || (this.currentState === answerState.refuseToQuote) || (this.currentState === answerState.contractCancelledbyEU) || (this.currentState === answerState.contractCancelledbySP) || (this.currentState === answerState.requestExpired) || (this.currentState === answerState.requestCancelledbyEU)) {
            return true
        }
        return false
    }

    isEUDeleteDisabled () {
        // console.log('isDisabled:' + indicator)
        if ((this.currentState === answerState.onSiteQuotation) || (this.currentState === answerState.quoteExpired) || (this.currentState === answerState.quoteCancelledbySP) || (this.currentState === answerState.refuseToQuote) || (this.currentState === answerState.contractCancelledbyEU) || (this.currentState === answerState.contractCancelledbySP) || (this.currentState === answerState.requestExpired) || (this.currentState === answerState.requestCancelledbyEU)) {
            return true
        }
        return false
    }

    isEUDeleteDisplayed () {
        // console.log('isDisabled:' + indicator)
        if ((this.currentState === answerState.pendingQuotation) ||
            (this.currentState === answerState.onSiteQuotation) ||
            (this.currentState === answerState.quoted) ||
            (this.currentState === answerState.contractValidated ||
            (this.currentState === answerState.quoteExpired) ||
            (this.currentState === answerState.requestExpired))) {
            return true
        }
        return false
    }

    // TESTED OK.
    // The end user is cancelling the request
    // TODO: this will need update see beloe
    requestCancelledbyEU (answerId) {
        console.log('requestCancelledbyEU from Answer state machine')
        // console.log('answerId:' + answerId)
        return this.changeState(answerState.requestCancelledbyEU)
    }

    isOnSiteButtonDisabled () {
        if (this.currentState === answerState.pendingQuotation) {
            return false
        }
        return true
    }

    isEUQuotationButtonDisabled () {
        if (this.currentState === answerState.onSiteQuotation) {
            return true
        }
        return false
    }

    isQuotationButtonDisabled () {
        if ((this.currentState === answerState.onSiteQuotation) || (this.currentState === answerState.pendingQuotation) || (this.currentState === answerState.quoted) || (this.currentState === answerState.quoteRefusedbyEU) || (this.currentState === answerState.contractValidated)) {
            return false
        }
        return true
    }

    isQuotationPDFDisplayButtonDisabled () {
        if ((this.currentState === answerState.quoted) || (this.currentState === answerState.quoteRefusedbyEU) || (this.currentState === answerState.contractValidated)) {
            return false
        }
        return true
    }

    isDeleteButtonDisplayed () {
        if ((this.currentState === answerState.pendingQuotation)) {
            return false
        }
        return true
    }

    isAppointmentButtonDisplayed () {
        if ((this.currentState === answerState.quoteExpired) || (this.currentState === answerState.quoteRefusedbyEU) || (this.currentState === answerState.quoteCancelledbySP) || (this.currentState === answerState.refuseToQuote) || (this.currentState === answerState.contractCancelledbyEU) || (this.currentState === answerState.contractCancelledbySP) || (this.currentState === answerState.requestCancelledbyEU) || (this.currentState === answerState.requestExpired)) {
            return false
        }
        return true
    }

    isSaveButtonQuotationDisplayed () {
        if ((this.currentState === answerState.contractValidated)) {
            return false
        }
        return true
    }

    isValidationButtonDisabled () {
        // console.log('FixedPrice?', this.isFixedPrice)
        // console.log('state?', this.currentState)
        if ((this.currentState === answerState.pendingQuotation) ||
            (this.currentState === answerState.onSiteQuotation) ||
            (this.currentState === answerState.contractValidated) ||
            (this.currentState === answerState.quoteExpired) ||
            (this.currentState === answerState.quoteRefusedbyEU) ||
            (this.currentState === answerState.quoteCancelledbySP) ||
            (this.currentState === answerState.refuseToQuote) ||
            (this.currentState === answerState.contractCancelledbyEU) ||
            (this.currentState === answerState.contractCancelledbySP) ||
            (this.currentState === answerState.requestCancelledbyEU) ||
            (this.currentState === answerState.requestExpired)) {
            return true
        }

        // We need a logic if quotation
        if (!this.isFixedPrice) {
            if ((this.currentState === answerState.quoted)) {
                return false
            }
            return true
        } else {
            // We need a logic if fixed price
            if ((this.currentState === answerState.quoted)) {
                return true
            }
            return false
        }
    }

    isCardAccountButtonDisplayed () {
        if ((this.currentState === answerState.contractValidated) || (this.currentState === answerState.onSiteQuotation)) {
            // console.log('isCardAccountButtonDisplayed - true')
            return true
        }
        // console.log('isCardAccountButtonDisplayed - false')
        return false
    }

    // Method to check if the EU should display the review button
    isCardReviewButtonDisplayedEU () {
        if ((this.currentState === answerState.contractValidated) || (this.currentState === answerState.quoteCancelledbySP) || (this.currentState === answerState.refuseToQuote) || (this.currentState === answerState.contractCancelledbySP)) {
            // console.log('isCardReviewButtonDisplayedEU - true')
            return true
        }
        // console.log('isCardReviewButtonDisplayedEU - false')
        return false
    }

    // Method to check if the SP should display the review button
    isCardReviewButtonDisplayedSP (item) {
        if (item && item.answer && item.answer.review && item.answer.review.id) {
            // console.log('isCardReviewButtonDisplayedSP - true')
            // console.log(item)
            return true
        }
        // console.log('isCardReviewButtonDisplayedSP - false')
        // console.log(item)
        return false
    }

    isvalidateRequestButtonDisplayed () {
        // In case we are in fix price
        if (this.isFixedPrice) {
            if (this.currentState === answerState.quoted) {
                return true
            }
        }
        return false
    }

    async onSiteRequest () {
        try {
            if (this.currentState === answerState.pendingQuotation) {
                // const myAnswer = { id: requestId, state: answerState.onSiteQuotation }
                // await API.graphql({ query: updateAnswerMutation, variables: { input: myAnswer }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
                // const myRequest = { id: myRequestObject.requestId, state: requestState.contractCancelledbySP }
                // API.graphql({ query: updateRequestMutation, variables: { input: myRequest }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
                logger.info('the state provided for the request is now:' + answerState.onSiteQuotation)
                return this.changeState(answerState.onSiteQuotation)
            }
        } catch (err) {
            logger.error(err)
            return false
        }
    }

    // We are updating the state of the answer to quoted state. //triggered by SP
    // TODO: this shall be removed because the logic is now handled by upsertAnswer transition
    makeQuotation (myRequestObject, answerId) {
        // console.log('makeQuotation')
        // console.log('myRequestObject:')
        // console.log(myRequestObject)
        if (this.currentState === answerState.pendingQuotation) {
            const myAnswer = { id: answerId, state: answerState.quoted }
            API.graphql({ query: updateAnswerMutation, variables: { input: myAnswer }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
                .then(apiData => {
                logger.info('the quote is provided for the request:' + myRequestObject.requestId)
                // TODO: becareful with this internal changeState!! this code is still needed
                return this.changeState(answerState.quoted)
                })
                .catch(err => {
                    logger.error(err)
                    return false
                })
        }
    }

    cancelledbySP (myRequestObject) {
        console.log('cancelledbySP from answer state machine')
        // console.log('myRequestObject:')
        // console.log(myRequestObject)
        // console.log(this.isFixedPrice)
        // Case we want to exclude
        if ((this.currentState === answerState.contractValidated) || (this.currentState === answerState.quoted) || (this.currentState === answerState.onSiteQuotation)) {
        } else {
            // console.log('not in the correct state to manage the delete')
            return false
        }
        // console.log('I pass here....')
        // We change the status of the Answer in any case
        // console.log(myAnswer)
        backEndCancelledBySP({ answerId: myRequestObject.answer.id })
        .then((output) => {
            if (myRequestObject.isFixedPrice === false) {
                // console.log('We check if we need to delete the file  attached ........................')
                if (myRequestObject.answer !== undefined) {
                    if (myRequestObject.answer.quotation !== null) {
                    Storage.remove(myRequestObject.answer.quotation)
                    // console.log('The file delete happened ........................')
                    }
                }
            }
            logger.info('Delete Request by Service Provider ok for:' + myRequestObject.requestId)
            return this.changeState(output.state)
        })

        // CASE FIXED PRICE
        if ((myRequestObject.isFixedPrice === true) && ((this.currentState === answerState.contractValidated) || (this.currentState === answerState.quoted))) {
            /* quoteCancelledbySP or contractCancelledbySP
            var today = new Date()
            // We will create an answer with the status 'refuseToQuote'
            const myAnswer = { answerServiceProviderId: getUserSub(), answerRequestId: myRequestObject.requestId, servicePrice: 0, datetimeExpiry: today.toISOString(), noteCustomer: '', serviceLegaldescription: '', state: answerState.refuseToQuote }
            console.log(myAnswer)
            API.graphql({ query: createAnswerMutation, variables: { input: myAnswer }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            .then(apiData => {
                console.log('success to create an answer')
                console.log(apiData)
                // We need to update the status
                myRequestObject.changeState(requestState.refuseToQuote)
                myRequestObject.answer.push(apiData)
                return true
                })
            .catch(err => {
                logger.error(err)
                return false
                })
            */
            /*
            const myRequest = { id: myRequestObject.requestId, state: requestState.contractCancelledbySP }
            API.graphql({ query: updateRequestMutation, variables: { input: myRequest }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
                .then(apiData => {
                console.log('The cancellation state in DB happened ........................')
                if (myRequestObject.isFixedPrice === false) {
                    console.log('We check if we need to delete the file  attached ........................')
                    if (myRequestObject.answer !== undefined) {
                        if (myRequestObject.answer.quotation !== null) {
                        Storage.remove(myRequestObject.answer.quotation)
                        console.log('The file delete happened ........................')
                        }
                    }
                }
                logger.info('Delete Request by Service Provider ok for:' + myRequestObject.requestId)
                return this.changeState(requestState.contractCancelledbySP)
                })
                .catch(err => {
                logger.error(err)
                return false
                })
                */
        }
    }

    async refusedQuoteByEU (myAnswer) {
        console.log('refusedQuoteByEU from answer state machine')
        const out = await backEndRefusedByEU({ answerId: myAnswer.id })
        return this.changeState(out.state)
    }

    async acceptQuoteByEU (myAnswer, serviceId) {
        console.log('acceptQuoteByEU from answer state machine')
        console.log(`serviceId:${serviceId}`)
        console.log(myAnswer)
        const params = { answerId: myAnswer.id, serviceId }
        const { state } = await backEndAcceptQuoteByEU(params)
        return this.changeState(state)
    }

    // Not tested.......//TODO - to test me
    async acceptFixedRequestBySP (myAnswer, serviceName, endUserId, serviceId) {
        try {
            if (this.isFixedPrice !== true) {
                return
            }
            console.log('acceptFixedRequestBySP from answer state machine')
            console.log(`myAnswer:${myAnswer}`)
            console.log(`serviceId: ${serviceId}`)
            if (this.currentState === answerState.quoted) {
                const myNewAnswer = { id: myAnswer.id, state: answerState.contractValidated }
                await API.graphql({ query: updateAnswerMutation, variables: { input: myNewAnswer }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
                logger.info('the quote is accepted by the SP' + myAnswer.id)
                // send email to SP, enduser
                const templateName = 'contractValidated'
                const templateParams = {
                    // --> PROBLEME A TROUVER : enduserId: await getUserSub(),
                    enduserId: endUserId,
                    // serviceProviderId: myAnswer.serviceProvider.id,
                    serviceProviderId: await getUserSub(),
                    servicePrice: myAnswer.servicePrice,
                    serviceName: serviceName,
                    sendTo: 'SP',
                    locale: i18n.locale,
                }
                var output = {}
                try {
                    logger.info('sending email to SP templateName:' + templateName)
                    let templateParamsStr = JSON.stringify(templateParams)
                    output = await API.graphql({ query: sendEmailGQL, variables: { templateName, templateParams: templateParamsStr }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
                    logger.info('send email to SP result:' + JSON.stringify(output))

                    logger.info('sending email to EndUser templateName:' + templateName)
                    templateParams.sendTo = 'EU'
                    templateParamsStr = JSON.stringify(templateParams)
                    output = await API.graphql({ query: sendEmailGQL, variables: { templateName, templateParams: templateParamsStr }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
                    logger.info('send email to EndUser result:' + JSON.stringify(output))
                } catch (err) {
                    logger.error('send email error:', err)
                    output.errorMessage = err.message
                    throw new Error(output.errorMessage)
                }
                return this.changeState(answerState.contractValidated)
            }
        } catch (err) {
            logger.error(err)
        }
    }
}

export function answerStateTranslation (state) {
    switch (state) {
        case 'a_PENDING_QUOTATION': return i18n.t('stateMachineLib.answer_PENDING_QUOTATION')
        case 'a_ONSITE_QUOTATION': return i18n.t('stateMachineLib.answer_ONSITE_QUOTATION')
        case 'a_QUOTED': return i18n.t('stateMachineLib.answer_QUOTED')
        case 'a_CONTRACT_VALIDATED': return i18n.t('stateMachineLib.answer_CONTRACT_VALIDATED')
        case 'a_QUOTE_EXPIRED': return i18n.t('stateMachineLib.answer_QUOTE_EXPIRED')
        case 'a_QUOTE_REFUSED_BY_EU': return i18n.t('stateMachineLib.answer_QUOTE_REFUSED_BY_EU')
        case 'a_QUOTE_CANCELLED_BY_SP': return i18n.t('stateMachineLib.answer_QUOTE_CANCELLED_BY_SP')
        case 'a_REFUSE_TO_QUOTE_BY_SP': return i18n.t('stateMachineLib.answer_REFUSE_TO_QUOTE_BY_SP')
        case 'a_CONTRACT_CANCELLED_BY_EU': return i18n.t('stateMachineLib.answer_CONTRACT_CANCELLED_BY_EU')
        case 'a_CONTRACT_CANCELLED_BY_SP': return i18n.t('stateMachineLib.answer_CONTRACT_CANCELLED_BY_SP')
        case 'a_REQUEST_CANCELLED_BY_EU': return i18n.t('stateMachineLib.answer_REQUEST_CANCELLED_BY_EU')
        case 'a_REQUEST_EXPIRED': return i18n.t('stateMachineLib.answer_REQUEST_EXPIRED')
    }
}
