<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to display a calendar
// Props:
//  - display-list-resource: display the resource list in case we are display the resource list of the SP for example. This is optional. Default is true.
// Events:
//  - calendarUpdated: event to trigger a function if we want to do something after the calendar is updated.
// Example of usage:
      <kdw-Calendar
        :key="'Calendar' + trickDisplay"
        v-model="resourceList"
        :display-list-resource="false"
        @calendarUpdated="calendarUpdated()"
      />
// ------------------------------------------------------------------------------>

<template>
  <v-container>
    <kdw-dialog-add-appointment-without-booking
      v-model="dialogAddAppointmentWithoutBooking"
      :resource-list="resourceList"
      @addAppointmentWithoutBooking="addAppointmentWithoutBooking($event)"
    />
    <v-row
      justify="space-around"
    >
      <v-col>
        <v-sheet
          v-show="displayListResource"
        >
          <v-menu
            bottom
            right
          >
            <template #activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ resourceNameList[selectedRessource] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list
              v-for="(ressource, k) in resourceNameList"
              :key="k"
            >
              <v-list-item @click="selectedRessource = k">
                <v-list-item-title>{{ resourceNameList[k] }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-sheet>
      </v-col>
      <v-col>
        <kdw-btn
          @click="openBookingDialog()"
        >
          {{ $t('button.addAppointmentWithoutBooking') }}
        </kdw-btn>
        <!--
        <kdw-icon-action-tooltip
          min-width="auto"
          tipkey="button.addAppointmentWithoutBooking"
          @click="openBookingDialog()"
        >
          mdi-plus-circle
        </kdw-icon-action-tooltip>
        -->
      </v-col>
    </v-row>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar
            flat
          >
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              {{ $t('Calendar.today') }}
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer />
            <v-menu
              bottom
              right
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="grey darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ $t(typeToLabel[type]) }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>{{ $t('Calendar.day') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>{{ $t('Calendar.week') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>{{ $t('Calendar.month') }}</v-list-item-title>
                </v-list-item>
                <!--
                <v-list-item @click="setType('4day')">
                  <v-list-item-title>5 days</v-list-item-title>
                </v-list-item>
                -->
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            :weekdays="weekday"
            :locale="getLocale()"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          />
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
            >
              <v-toolbar
                :color="selectedEvent.color"
                dark
              >
                <v-toolbar-title v-html="selectedEvent.name" />
                <v-spacer />
                <!-- We propose a menu related to the appointment -->
                <v-menu
                  v-if="selectedEvent.type !== 'Holiday'"
                  offset-y
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          @click="deleteAppointment(selectedEvent)"
                        >
                          <v-icon
                            center
                          >
                            mdi-delete
                          </v-icon>
                          {{ $t('RequestEU.delAppoint') }}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
              <v-card-text>
                <u>{{ $t('calendar.start') }}</u>: {{ changeDate(selectedEvent.startISO) }}
              </v-card-text>
              <v-card-text>
                <u>{{ $t('calendar.end') }}</u>: {{ changeDate(selectedEvent.endISO) }}
              </v-card-text>
              <v-card-text
                v-show="getResourceName(selectedEvent) !== ''"
              >
                <u>{{ $t('calendar.by') }}</u>:
                <div class="grey--text ms-4">
                  <v-icon> mdi-account-key </v-icon>
                  {{ getResourceName(selectedEvent) }}
                </div>
              </v-card-text>
              <v-card-text
                v-show="getUserName(selectedEvent) !== ''"
              >
                <u>{{ $t('calendar.for') }}</u>:
                <div class="grey--text ms-4">
                  <v-icon> mdi-account-circle </v-icon>
                  {{ getUserName(selectedEvent) }}
                </div>
                <div
                  v-show="getPhone(selectedEvent) !== ''"
                  class="grey--text ms-4"
                >
                  <v-icon> mdi-phone </v-icon>
                  {{ getPhone(selectedEvent) }}
                </div>
                <div
                  v-show="getMail(selectedEvent) !== ''"
                  class="grey--text ms-4"
                >
                  <v-icon> mdi-email </v-icon>
                  {{ getMail(selectedEvent) }}
                </div>
              </v-card-text>
              <v-card-text>
                <span v-html="selectedEvent.details" />
              </v-card-text>
              <v-card-actions>
                <kdw-btn
                  text
                  @click="selectedOpen = false"
                >
                  {{ $t('button.close') }}
                </kdw-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { translateLocaleBrowserType } from '@/helpers/Navigation'
  import { ResourceList } from '@/services/ResourceList'
  import { DateTime } from 'luxon'
  import { deleteAppointment } from '@/services/Appointment'
  import { sendEmail } from '@/services/commonTools'

  export default {
    name: 'Calendar',

    props: {
      value: {
        type: Object,
        required: true,
      },
      displayListResource: {
        type: Boolean,
        default: true,
      },
    },

    data: () => {
      return {
        focus: '',
        type: 'month',
        typeToLabel: {
          month: 'Calendar.month',
          week: 'Calendar.week',
          day: 'Calendar.day',
          // '4day': '5 Days',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        // colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        // names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
        weekday: [1, 2, 3, 4, 5, 6, 0],
        resourceList: [],
        resourceNameList: [],
        selectedRessource: 0,
        dialogAddAppointmentWithoutBooking: false,
      }
    },
    watch: {
      selectedRessource: function (val) {
        console.log('selectedRessource changed', this.selectedRessource, ' ', this.resourceNameList[this.selectedRessource])
        // We clean the event list
        this.events.splice(0, this.events.length)
        var myTemporaryList = new ResourceList()
        if (val === 0) {
          myTemporaryList.buildEventForAListOfRessource(this.resourceList, this.events)
            .then(() => {
              this.cleanEventNames()
            })
        } else {
          // We  need to build a table with the needed element
          var tableRes = []
          tableRes.push(this.resourceList[val - 1])
          myTemporaryList.buildEventForAListOfRessource(tableRes, this.events)
            .then(() => {
              this.cleanEventNames()
            })
        }
      },
    },
    created () {
      this.parent = this
      this.resourceList = [...this.value.list]
      // console.log('-------------------this.resourceList in Created:')
      // console.log(this.resourceList)
      this.resourceNameList.push(this.$i18n.t('Calendar.all'))
      var myTemporaryList = new ResourceList()
      myTemporaryList.buildEventForAListOfRessource(this.resourceList, this.events)
        .then(() => {
          // console.log('this.events after created -------')
          // console.log(this.events)
          // console.log('this.displayListResource')
          // console.log(this.displayListResource)
          // now the ressources are loaded with the name so we can build the list
          if (this.displayListResource) {
            for (var i = 0; i < this.resourceList.length; i++) {
              this.resourceNameList.push(this.resourceList[i].firstname + ' ' + this.resourceList[i].lastname.toUpperCase())
            }
          }
          this.cleanEventNames()
        })
    },

    mounted () {
      this.$refs.calendar.checkChange()
    },

    methods: {
      /*
      setType (type) {
        switch (type) {
          case 'month': this.type = 'month'; this.weekday = [1, 2, 3, 4, 5, 6, 0]; break
          case 'week': this.type = 'week'; this.weekday = [1, 2, 3, 4, 5, 6, 0]; break
          case '4day': this.type = '4day'; this.weekday = [1, 2, 3]; break
          default: this.type = 'week'; this.weekday = [1, 2, 3, 4, 5, 6, 0]; break
        }
      },
      */
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      updateRange ({ start, end }) {
        // WAS PART OF TYHE ORIGINAL DESIGN FROM VUETIFY
        // const newEventList = []
        // this.buildEvents(this.resourceList, newEventList)
        // var myTemporaryList = new ResourceList()
        // myTemporaryList.buildEventForAListOfRessource(this.resourceList, newEventList)
        //  .then(() => {
        //    this.events = newEventList
        //  })

        /*
        const events = []
        const min = new Date(`${start.date}T00:00:00`)
        const max = new Date(`${end.date}T23:59:59`)
        const days = (max.getTime() - min.getTime()) / 86400000
        const eventCount = this.rnd(days, days + 20)
        for (let i = 0; i < eventCount; i++) {
          const allDay = this.rnd(0, 3) === 0
          const firstTimestamp = this.rnd(min.getTime(), max.getTime())
          const first = new Date(firstTimestamp - (firstTimestamp % 900000))
          const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
          const second = new Date(first.getTime() + secondTimestamp)
          events.push({
            name: this.names[this.rnd(0, this.names.length - 1)],
            start: first,
            end: second,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: !allDay,
          })
        }
        this.events = events
        // console.log(events)
        */
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      getLocale () {
        return translateLocaleBrowserType(this.$i18n.locale)
      },
      changeDate (date) {
        // console.log(date)
        // .log(this.$i18n.locale)
        if (date !== undefined) {
          return DateTime.fromISO(date).setLocale(this.$i18n.locale).toFormat('DDD T')
          /* if (this.$i18n.locale === 'fr') {
            return formatDate(date, 'DD-MM-YYYY HH:mm')
          } else {
            return formatDate(date, 'MM-DD-YYYY HH:mm')
          } */
        }
      },
      getResourceName (selectedEvent) {
        var temp = ''
        if ((selectedEvent.resourceFirstname !== null) && (selectedEvent.resourceFirstname !== undefined)) {
          temp = temp + selectedEvent.resourceFirstname + ' '
        }
        if ((selectedEvent.resourceLastname !== null) && (selectedEvent.resourceLastname !== undefined)) {
          temp = temp + selectedEvent.resourceLastname
        }
        return temp
      },
      getUserName (selectedEvent) {
        var temp = ''
        if ((selectedEvent.enduserFirstName !== null) && (selectedEvent.enduserFirstName !== undefined)) {
          temp = temp + selectedEvent.enduserFirstName + ' '
        }
        if ((selectedEvent.enduserLastname !== null) && (selectedEvent.enduserLastname !== undefined)) {
          temp = temp + selectedEvent.enduserLastname
        }
        return temp
      },
      getPhone (selectedEvent) {
        if ((selectedEvent.enduserPhone === null) || (selectedEvent.enduserPhone === undefined)) {
          return ''
        }
        return selectedEvent.enduserPhone
      },
      getMail (selectedEvent) {
        if ((selectedEvent.enduserMail === null) || (selectedEvent.enduserMail === undefined)) {
          return ''
        }
        return selectedEvent.enduserMail
      },
      cleanEventNames () {
        for (var i = 0; i < this.events.length; i++) {
          if (this.events[i].name === 'Calendar.holiday') {
            this.events[i].name = this.$i18n.t('Calendar.holiday')
          }
        }
      },
      checkAppointmentType (selectedEvent) {
        if (typeof selectedEvent.appointementId !== 'undefined') {
          console.log('true')
          return true
        }
        return false
      },
      deleteAppointment (selectedEvent) {
        // console.log('deleteAppointment')
        // console.log('selectedEvent')
        // console.log(selectedEvent)
        // We search for the event in the list
        var found = false
        var i = 0
        while ((found === false) && (i < this.events.length)) {
          if (selectedEvent.startISO === this.events[i].startISO) {
            found = true
          }
          i++
        }
        if (found) {
          // We delete the event from the event list
          i--
          this.events.splice(i, 1)
          // We delete the event from the DB
          deleteAppointment(selectedEvent.appointementId, selectedEvent.appointmentAnswersId)
          // We close the window
          this.selectedOpen = false
          this.$root.kdwsnackbar.alert('addAppointmentWithoutBooking.deletedAppoint', 2000)
          // sending appointment deleted to Resource
          var params = { serviceName: selectedEvent.name, date: DateTime.fromISO(selectedEvent.startISO).toFormat('yyyy-LL-dd'), time: DateTime.fromISO(selectedEvent.startISO).toFormat('HH:mm'), resourceId: selectedEvent.resourceID, sendTo: 'RE' }
          sendEmail('bookingAppointmentDeletedResource', params)
        }
      },
      openBookingDialog () {
        // console.log('openBookingDialog')
        this.dialogAddAppointmentWithoutBooking = true
      },
      addAppointmentWithoutBooking (eventInformationString) {
        // console.log('addAppointmentWithoutBooking')
        var event = JSON.parse(eventInformationString)
        // console.log('event received by messaging')
        // console.log(event)
        // some object needs to be re-created to work.
        var start = new Date(event.start)
        var end = new Date(event.end)
        event.start = start
        event.end = end
        this.events.push(event)
        this.$root.kdwsnackbar.alert('addAppointmentWithoutBooking.addedAppoint', 2000)
        // We are sending the mail associated to inform SP & EU ??????? EU????
        var params = { serviceName: event.name, date: DateTime.fromISO(event.startISO).toFormat('yyyy-LL-dd'), time: DateTime.fromISO(event.startISO).toFormat('HH:mm'), resourceId: event.resourceID, sendTo: 'RE' }
        sendEmail('bookingAppointmentConfirmedResource', params)
      },
    },
  }
</script>
