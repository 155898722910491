// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the manually generated MUTATIONS ON GRAPHQL
// ------------------------------------------------------------------------------

export const doStateMachine = /* GraphQL */ `
  mutation doStateMachine($type:String!, $transition: String!, $params: String!) {
    doStateMachine(type: $type, transition: $transition, params: $params)
  }
`
