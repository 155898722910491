<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to display the message linked to the cookie bar to the end user:
// Props:
// - snackbarTimeout: time to display the message in ms (default = 2000). Use -1 to keep it displayed
// - snackbarMessage: Message to be displayed to the user.
// Example of usage:
  <kdw-Cookiebar-card
    v-model="cookieUpdate"
  />
  with cookieUpdate that need to be set to true to display the CookieBar
  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  NORMALLY WE NEED TO RE-ASK FOR CONSENT IN 13 MONTHS but this is not implemented here
  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// ------------------------------------------------------------------------------>

<template>
  <!--
  v-bind="$attrs" -> to inherit all the other properties
  v-on="$listeners" -> to inherit all the other listeners event
  -->
  <v-snackbar
    v-bind="$attrs"
    :timeout="-1"
    color="blue-grey"
    bottom
    v-on="$listeners"
  >
    <p v-html="snackbarMessage" />
    <template #action="{ attrs }">
      <v-btn
        text
        outlined
        v-bind="attrs"
        @click="closeDialog()"
      >
        {{ $t('button.close') }}
      </v-btn>
      <v-btn
        text
        outlined
        v-bind="attrs"
        @click="acceptDialog()"
      >
        {{ $t('button.accept') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'
  import { buildCookieSentenceTagBased, dateDiff } from '@/helpers/GenericFunctions'
  import { updateCookie, checkCookie } from '@/services/Consent'

  export default {
    name: 'KDWCookieBar',

    mixins: [Heading],

    data: () => {
      return {
        barStatus: false,
        snackbarMessage: '',
      }
    },

    watch: {
      snackbarMessage () {
        return this.snackbarMessage
      },
    },

    created: function () {
      this.snackbarMessage = ''
      // the cookieGeneralChecked parameter is used to check if the cookie has been validated by the end user
      var cookieParameters = { cookieGeneralChecked: false }

      // We download the cookie status
      checkCookie()
        .then((cookieValue) => {
          // console.log('cookieValue:', cookieValue)
          if (cookieValue.cookieParameters !== '') {
            cookieParameters = JSON.parse(cookieValue.cookieParameters)
            // console.log('cookieParameters:')
            // console.log(cookieParameters)
            // We check if we passed the 13 months, we need to reset the consent.
            var dateCookie = new Date(new Date(cookieValue.cookieUpdateDate).toISOString().substr(0, 10))
            if (dateDiff(dateCookie) >= 13) {
              // console.log('we reset because this is old')
              cookieParameters.cookieGeneralChecked = false
              // We are setting up the cookie to false as this is too long the consent have not been given
              updateCookie(false)
            }
            // console.log('now we check if the cookie needs to be displayed.')
            if (cookieParameters.cookieGeneralChecked === false) {
              this.barStatus = true
              this.emitResult()
              buildCookieSentenceTagBased(this.$i18n.t('AccountEU.cookie1'), this.$i18n.t('AccountEU.cookie2'))
                .then((result) => {
                  this.snackbarMessage = result
                  // console.log('we build the sentence:', this.snackbarMessage)
                  // console.log(result)
                })
            } else {
              this.closeDialog()
            }
          } else {
            this.closeDialog()
          }
        })

      // console.log('this.$attr = ', this.$attrs)
      // console.log('this.$listeners = ', this.$listeners)
    },
    methods: {
      emitResult () {
        this.$emit('input', this.barStatus)
      },
      closeDialog () {
        this.barStatus = false
        this.emitResult()
      },
      acceptDialog () {
        // this.barStatus = false
        // this.emitResult()
        // We update the cookie with OK for consent from the user
        updateCookie(true)
          .then(() => {
            this.closeDialog()
          })
      },
    },
  }
</script>
