// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the MiniWeb Template library
// ------------------------------------------------------------------------------

import { Logger } from 'aws-amplify'

import { miniwebFormatedData as miniwebFormatedDataBasic } from '@/services/miniweb/templates/basic/'
import { miniwebFormatedData as miniwebFormatedDataElegant } from '@/services/miniweb/templates/elegant/'

const logger = new Logger('MiniwebResolver')

// return list of current Miniweb Templates
export const TEMPLATE_MINIWEB_LIST = [
  { id: 'c6jxmq', name: 'Basic', logo: 'miniweb/basic/MiniwebFrontBasic.png' },
  { id: 'qqueca', name: 'Elegant', logo: 'miniweb/elegant/MiniwebFrontElegant.png' },
]

// TODO add theme selection here
// Name contains the type of Layout, loadedParam contains all the params loaded from the specific Service Provider page

// export function resolveByName (name = TEMPLATE_MINIWEB_LIST[0].name, loadedParam) {
export function resolveMiniWebByCriteria (name = TEMPLATE_MINIWEB_LIST[0].name, loadedParam) {
  logger.info('ResolveByName:', name)
  // TO DO to point to the rigth place.

    // case Basic:
    if ((TEMPLATE_MINIWEB_LIST[0].name === name) || (TEMPLATE_MINIWEB_LIST[0].id === name)) {
      // console.log('Resolver for Basic')
      // console.log(miniwebFormatedDataBasic(loadedParam))
      var dataBasic = miniwebFormatedDataBasic(loadedParam)
      // console.log(dataBasic)
      return dataBasic
    }
    // case elegant:
    if ((TEMPLATE_MINIWEB_LIST[1].name === name) || (TEMPLATE_MINIWEB_LIST[1].id === name)) {
      // console.log('Resolver for Elegant')
      var dataElegant = miniwebFormatedDataElegant(loadedParam)
      // console.log(dataElegant)
      return dataElegant
    }
    // console.log('Resolver for Default - Basic')
    // var data = miniwebFormatedDataBasic(loadedParam)
    // console.log(data)
    return {}
}
