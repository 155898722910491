<template>
  <v-card
    :color="color"
    :elevation="value ? 12 : 0"
    class="pa-2"
    outlined
    tile
  >
    <v-card
      flat
      outlined
      tile
    >
      <div class="pa-6 pb-12 mb-8">
        <base-title
          :title="$t(title)"
          class="text-uppercase"
          space="1"
        />

        <base-subtitle
          :title="$t(subtitle)"
          space="8"
        />

        <div class="d-flex align-center justify-center">
          <base-heading
            mobile-size="display-2"
            space="0"
            tag="h3"
          >
            <sup><small>€</small></sup>{{ price }}
          </base-heading>

          <div class="text-left ml-4 text-body-2 font-weight-bold mt-1 text-uppercase">
            HT {{ $t('pricingSection.per') }}
            <base-divider
              align="left"
              color="primary"
              space="0"
            />
            {{ $t(interval) }}
          </div>
        </div>
        <div>
          <base-heading
            mobile-size="display-2"
            space="0"
            tag="h3"
          >
            +
          </base-heading>
        </div>
        <div>
          <base-heading
            mobile-size="display-2"
            space="0"
            tag="h3"
          >
            <sup><small>{{ $t('pricingSection.payPerUse') }}</small></sup>
          </base-heading>
        </div>
      </div>

      <div class="mb-8" />

      <div class="pb-12">
        <div class="px-8 mb-12">
          <template v-for="(feature, i) in features">
            <div
              :key="feature"
              class="my-3"
              v-text="feature"
            />

            <v-divider
              v-if="i + 1 < features.length"
              :key="`divider-${i}`"
            />
          </template>
        </div>

        <base-btn
          :outlined="!value"
          width="196"
          @click="openConnexion()"
        >
          {{ $t('button.createAccountInfo') }}
        </base-btn>
      </div>
    </v-card>
  </v-card>
</template>

<script>
  import { navigate } from '@/helpers/Navigation'

  export default {
    name: 'PriceCard',

    provide: {
      heading: { align: 'center' },
    },

    props: {
      features: {
        type: Array,
        default: () => ([]),
      },
      price: [Number, String],
      subtitle: String,
      title: String,
      interval: {
        type: String,
        required: true,
      },
      value: Boolean,
    },

    computed: {
      color () {
        if (!this.value) return undefined

        return this.$vuetify.isDark ? 'white' : 'grey lighten-5'
      },
    },

    methods: {
      openConnexion () {
        navigate(this, 'PLogin')
      },
    },
  }
</script>
