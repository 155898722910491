// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the fata format for miniWeb
// ------------------------------------------------------------------------------

import { isObjectEmpty } from '@/helpers/GenericFunctions'

// ------------------- Data Management ----------------------------------------------
export function commonData (args, loadedParam) {
  if (!('id' in args) || !('name' in args)) {
      throw new Error('id and name shall be defined.')
  }
  // console.log('loadedParam')
  // console.log(loadedParam)
  var formatedCommonData = {}
  formatedCommonData.id = args.id
  formatedCommonData.name = args.name
  formatedCommonData.toggleMiniweb = false

  // formatedCommonData.themeColor = '#2F6FAC' // '#E4007A' // 2F6FAC'
  if (!isObjectEmpty(loadedParam)) {
    if (loadedParam.themeId) {
      formatedCommonData.themeColor = loadedParam.themeId
    }
    if (loadedParam.toggleMiniweb !== null && loadedParam.toggleMiniweb !== undefined) formatedCommonData.toggleMiniweb = loadedParam.toggleMiniweb
  }
  return formatedCommonData
}

export function identityData (loadedParam) {
    // console.log('loadedParam')
    // console.log(loadedParam)
    var formatedIdentity = {}
    formatedIdentity.companyName = ''
    formatedIdentity.firstname = ''
    formatedIdentity.sirect = ''
    formatedIdentity.serviceProviderID = ''
    formatedIdentity.logo = ''
    formatedIdentity.address1 = ''
    formatedIdentity.address2 = ''
    formatedIdentity.postcode = ''
    formatedIdentity.townName = ''
    formatedIdentity.countryName = ''
    formatedIdentity.email = ''
    formatedIdentity.phone = ''
    if (isObjectEmpty(loadedParam)) return formatedIdentity
    if (loadedParam.name) formatedIdentity.companyName = loadedParam.name
    if (loadedParam.firstname) formatedIdentity.firstname = loadedParam.firstname
    if (loadedParam.toggleSiret && loadedParam.siret) formatedIdentity.siret = loadedParam.siret
    if (loadedParam.serviceProviderID) formatedIdentity.serviceProviderID = loadedParam.serviceProviderID
    if (loadedParam.logo) formatedIdentity.logo = loadedParam.logo
    if (loadedParam.toggleAddress && loadedParam.address1) formatedIdentity.address1 = loadedParam.address1
    if (loadedParam.toggleAddress && loadedParam.address2) formatedIdentity.address2 = loadedParam.address2
    if (loadedParam.toggleAddress && loadedParam.postcode) formatedIdentity.postcode = loadedParam.postcode
    if (loadedParam.toggleAddress && loadedParam.postcodecity && loadedParam.postcodecity.townName) formatedIdentity.townName = loadedParam.postcodecity.townName
    if (loadedParam.toggleAddress && loadedParam.country && loadedParam.country.name) formatedIdentity.countryName = loadedParam.country.name
    if (loadedParam.toggleEmail && loadedParam.email) formatedIdentity.email = loadedParam.email
    if (loadedParam.togglePhone && loadedParam.phone) formatedIdentity.phone = loadedParam.phone
    // console.log('formatedIdentity')
    // console.log(formatedIdentity)
    return formatedIdentity
}

export function showroomData (loadedParam) {
  var formatedshowroomData = {}
  formatedshowroomData.description = ''
  formatedshowroomData.priceRange = ''
  formatedshowroomData.jobName = ''
  formatedshowroomData.backgroundPicture = ''
  formatedshowroomData.pictures = []
  formatedshowroomData.picturesLoop = false
  // console.log('loadedParam - showroom:')
  // console.log(loadedParam)
  if (isObjectEmpty(loadedParam)) return formatedshowroomData
  // Assign the data according to the content and the content.
  if (loadedParam.showroomDescription) formatedshowroomData.description = loadedParam.showroomDescription
  if (loadedParam.togglePriceRange && loadedParam.priceRange) formatedshowroomData.priceRange = loadedParam.priceRange
  if (loadedParam.jobName) formatedshowroomData.jobName = loadedParam.jobName
  if (loadedParam.showroomPictures) formatedshowroomData.pictures = loadedParam.showroomPictures
  if (loadedParam.showroomPicturesLoop) formatedshowroomData.picturesLoop = loadedParam.showroomPicturesLoop
  if (loadedParam.showroomBackground) formatedshowroomData.backgroundPicture = loadedParam.showroomBackground
  // console.log('formatedshowroomData - showroom:')
  // console.log(formatedshowroomData)
  return formatedshowroomData
}

export function ratingsData (loadedParam) {
  var formatedRatingsData = {}
  formatedRatingsData.score = ''
  if (isObjectEmpty(loadedParam)) return formatedRatingsData
  if (loadedParam.toggleScore && loadedParam.score) formatedRatingsData.score = loadedParam.score
  return formatedRatingsData
}

export function servicesData (loadedParam) {
  var formatedservicesData = {}
  formatedservicesData.description = ''
  formatedservicesData.backgroundPicture = ''
  if (isObjectEmpty(loadedParam)) return formatedservicesData
  // Assign the data according to the content and the content.
  if (loadedParam.servicesDescription) formatedservicesData.description = loadedParam.servicesDescription
  if (loadedParam.servicesBackground) formatedservicesData.backgroundPicture = loadedParam.servicesBackground
  return formatedservicesData
}
/*
  TODO
  experiences: {
  },
  diploma: {
  },
  testimonies: {
  },
  specialties: {
  },
  showRoom: {
  },
  activity: {
  },
  verbatim: {
  },
}
*/
/*
// ------------------- Toggle Management ----------------------------------------------
export function identityToggle (loadedParam) {
  // console.log('loadedParam')
  // console.log(loadedParam)
  var formatedIdentityToggle = {}
  formatedIdentityToggle.address = true
  formatedIdentityToggle.phone = true
  formatedIdentityToggle.mail = true
  formatedIdentityToggle.rating = true
  formatedIdentityToggle.priceRange = true
  if (!isObjectEmpty(loadedParam)) {
  // TO DO
  }
  return formatedIdentityToggle
}

export function showroomToggle (loadedParam) {
  // console.log('loadedParam')
  // console.log(loadedParam)
  var formatedShowroomToggle = {}
  formatedShowroomToggle.priceRange = true
  if (!isObjectEmpty(loadedParam)) {
  // TO DO
  }
  return formatedShowroomToggle
}

export function ratingsToggle (loadedParam) {
  // console.log('loadedParam')
  // console.log(loadedParam)
  var formatedRatingsToggle = {}
  formatedRatingsToggle.score = true
  if (!isObjectEmpty(loadedParam)) {
  // TO DO
  }
  return formatedRatingsToggle
}
*/
