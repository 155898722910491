
export function navigate (vue, routeName) {
    // console.log(`[navigate()] vue:${vue.$route.name}, routeName:${routeName}`)
    if (vue.$route.name !== routeName) {
        // nextTick avoids 'Error: Navigation cancelled from "" to "" with a new navigation.'
        // console.log('[navigate()] clear navigateNext flag')
        vue.navigateNext = undefined
        vue.$nextTick(() => {
            vue.$router.push({ name: routeName }).catch({
                // navigation cancelled event happens here
                // nothing to care about in this case
                // do nothing
            })
        })
    }
}

// Obj is a pointer to the page to be able to access to the i18n locale
export function loadBrowserLocale () {
    try {
      var locales = getBrowserLocales()
      // console.log('----> locales', locales)
      if (locales.length > 0) {
        // We defined the language of the site using the first locale
        return verifyLocale(locales[0])
      }
      return verifyLocale()
    } catch (err) {
      console.log(err)
      return verifyLocale()
    }
}

export function verifyLocale (locale) {
  switch (locale) {
    case 'en': return 'en'
    case 'fr': return 'fr'
    default: return 'fr'
  }
}

export function translateLocaleBrowserType (locale) {
  switch (locale) {
    case 'en': return 'en-EN'
    case 'fr': return 'fr-FR'
    default: return 'fr-FR'
  }
}

function getBrowserLocales (options = {}) {
    const defaultOptions = {
      languageCodeOnly: true,
    }

    const opt = {
      ...defaultOptions,
      ...options,
    }

    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages

    if (!browserLocales) {
      return undefined
    }

    return browserLocales.map(locale => {
      const trimmedLocale = locale.trim()

      return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale
    })
  }
