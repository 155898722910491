// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the categories and Sub categories library using a class
// ------------------------------------------------------------------------------

// import { getUserSub } from '@/services/User'
// import { listServicesSimplify, listCategoriesCheck } from '@/graphql/kdwqueries'
import { Logger, API } from 'aws-amplify'
// import { createCategory as createCategoryMutation } from '@/graphql/mutations'
import { listCategories } from '@/graphql/queries'
import { listSubCategoriesCompact } from '@/graphql/kdwqueries'
import { compare } from '@/helpers/GenericFunctions'
import i18n from '@/plugins/i18n.js'
import { formatCategoryOrSubCategoryName } from '@/helpers/Formatter'

const logger = new Logger('CategoryClass')

export class Categories {
  list = []
  constructor () {
    logger.info('Categories created.')
    this.empty()
  }

  empty () {
    this.list.splice(0, this.list.length)
  }

  async load () {
    this.empty()
    try {
      const apiData = await API.graphql({ query: listCategories, authMode: 'AWS_IAM' })
      const categoryFromAPI = apiData.data.listCategories.items
      // console.log('categoryFromAPI:')
      // console.log(categoryFromAPI)
      // console.log('categoryFromAPI[0]:', categoryFromAPI[0].name)
      for (var i = 0; i < categoryFromAPI.length; i++) {
        if (categoryFromAPI[i].name) {
          this.list.push({ name: categoryFromAPI[i].name, nameTranslated: formatCategoryOrSubCategoryName(categoryFromAPI[i].name, categoryFromAPI[i].countryCode, categoryFromAPI[i].nameTag), id: categoryFromAPI[i].id, countryCode: categoryFromAPI[i].countryCode, nameTag: categoryFromAPI[i].nameTag, subCategories: [] })
        }
      }
      return apiData.data.listCategories.nextToken
    } catch (err) {
      logger.error(err)
      return null
    }
  }

  async loadSubCategories (itemNb) {
      this.list[itemNb].subCategories.splice(0, this.list[itemNb].subCategories.length)
      try {
        var nextTokenStart = 'start'
        const myFilter = { categorySubCategorysId: { eq: this.list[itemNb].id } }
        var apiData, subCategoriesFromAPI
        var i = 0
        while (nextTokenStart !== null) {
          if (nextTokenStart === 'start') {
            apiData = await API.graphql({ query: listSubCategoriesCompact, variables: { filter: myFilter, limit: 4000 }, authMode: 'AWS_IAM' })
          } else {
            apiData = await API.graphql({ query: listSubCategoriesCompact, variables: { filter: myFilter, limit: 4000, nextToken: nextTokenStart }, authMode: 'AWS_IAM' })
          }
          nextTokenStart = apiData.data.listSubCategories.nextToken
          // console.log('apiData')
          // console.log(apiData)
          subCategoriesFromAPI = apiData.data.listSubCategories.items
          if (subCategoriesFromAPI.length !== 0) {
            for (i = 0; i < subCategoriesFromAPI.length; i++) {
              this.list[itemNb].subCategories.push({ name: subCategoriesFromAPI[i].name, nameTranslated: formatCategoryOrSubCategoryName(subCategoriesFromAPI[i].name, subCategoriesFromAPI[i].countryCode, subCategoriesFromAPI[i].nameTag), id: subCategoriesFromAPI[i].id, countryCode: subCategoriesFromAPI[i].countryCode, nameTag: subCategoriesFromAPI[i].nameTag })
            }
          }
        }
    } catch (err) {
      logger.error(err)
    }
  }

  console () {
    console.log('Categories list:')
    console.log(this.list)
  }

  sort () {
    this.list.sort(compare)
  }

  // Return Bolean if the name of the category from the CategoryTable exist
  isCategoryNameExist (catNameToCheck) {
    const catNameToCheckToLowerCase = catNameToCheck.toLowerCase()
    for (var i = 0; i < this.list.length; i++) {
      if (this.list[i].nameTag) {
        // console.log('verifCategoryName:', verifCategoryName.toLowerCase(), '-', (i18n.t(nameTag)).toLowerCase(), i18n.locale)
        if (catNameToCheckToLowerCase === this.list[i].nameTranslated.toLowerCase()) return { result: true, item: i }
      } else {
        if ((i18n.locale === this.list[i].countryCode) && (catNameToCheckToLowerCase === this.list[i].name.toLowerCase())) return { result: true, item: i }
      }
    }
    return { result: false, item: 0 }
  }

  isSubCategoryNameExist (subCatNameToCheck, listItemNb) {
    const subCatNameToCheckToLowerCase = subCatNameToCheck.toLowerCase()
    // console.log(subCatNameToCheckToLowerCase)
    // console.log(this.list[listItemNb].subCategories)
    for (var i = 0; i < this.list[listItemNb].subCategories.length; i++) {
      // console.log('I pass here for: ', this.list[listItemNb].subCategories[i].name)
      if (this.list[listItemNb].subCategories[i].nameTag) {
        if (subCatNameToCheckToLowerCase === this.list[listItemNb].subCategories[i].nameTranslated.toLowerCase()) return true
      } else {
        if ((i18n.locale === this.list[listItemNb].subCategories[i].countryCode) && (subCatNameToCheckToLowerCase === this.list[listItemNb].subCategories[i].name.toLowerCase())) return true
      }
    }
    return false
  }

  foundEntryFromCatId (idToSearch) {
    for (var i = 0; i < this.list.length; i++) {
      if (idToSearch === this.list[i].id) return { result: true, item: i }
    }
    return { result: false, item: 0 }
  }
}
