/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      euReviewDateTime
      euReviewComment
      spRating
      spReviewDateTime
      spReviewComment
      serviceID
      serviceProviderID
      answerID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $id: ID
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReviews(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        euReviewDateTime
        euReviewComment
        spRating
        spReviewDateTime
        spReviewComment
        serviceID
        serviceProviderID
        answerID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const reviewByServiceID = /* GraphQL */ `
  query ReviewByServiceID(
    $serviceID: ID!
    $spRating: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewByServiceID(
      serviceID: $serviceID
      spRating: $spRating
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        euReviewDateTime
        euReviewComment
        spRating
        spReviewDateTime
        spReviewComment
        serviceID
        serviceProviderID
        answerID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      dateTime
      resourceID
      resourceFirstname
      resourceLastname
      serviceID
      serviceName
      serviceDuration
      enduserID
      enduserFirstName
      enduserLastname
      enduserPhone
      enduserMail
      answers {
        items {
          id
          appointmentID
          answerID
          createdAt
          updatedAt
          owner
          ownerRead
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $id: ID
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppointments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        dateTime
        resourceID
        resourceFirstname
        resourceLastname
        serviceID
        serviceName
        serviceDuration
        enduserID
        enduserFirstName
        enduserLastname
        enduserPhone
        enduserMail
        answers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getServiceResources = /* GraphQL */ `
  query GetServiceResources($id: ID!) {
    getServiceResources(id: $id) {
      id
      serviceResourcesId
      service {
        id
        serviceProviderID
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        serviceDescription
        notePersonal
        serviceLegaldescription
        isFixedPrice
        isActivated
        keywords
        serviceAutoAccepted
        serviceDuration
        bufferTimeBefore
        bufferTimeAfter
        ServicePrice
        currency
        onlineBooking
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        requests {
          nextToken
        }
        resources {
          nextToken
        }
        serviceRating
        reviewNb
        createdAt
        updatedAt
        serviceCategoryId
        serviceSubCategoryId
        servicePostcodecityId
        owner
      }
      resourceServicesId
      resource {
        id
        ownerSelf
        firstname
        lastname
        phone
        email
        login
        webSiteAccess
        canBookOnline
        picture
        interval
        weekWorkingDays
        dayOffList
        serviceProviderResourcesId
        serviceProvider {
          id
          owner
          ownerRead
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          namecpy
          logocpy
          siret
          siretVerified
          addresscpy1
          addresscpy2
          postcodecpy
          phonecpy
          emailcpy
          consentcpy
          rcs
          legalStatus
          parameters
          countMonthMaxActiveService
          walletSize
          countMonthAppearInSearch
          MaxWalletReached
          banned
          logoHistory
          recordHistory
          createdAt
          updatedAt
          serviceProviderPostcodecityId
          serviceProviderCountryId
          serviceProviderPostcodecitycpyId
          serviceProviderCountrycpyId
        }
        services {
          nextToken
        }
        parameters
        createdAt
        updatedAt
        owner
      }
      resourceID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listServiceResources = /* GraphQL */ `
  query ListServiceResources(
    $id: ID
    $filter: ModelServiceResourcesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServiceResources(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        serviceResourcesId
        service {
          id
          serviceProviderID
          categoryID
          name
          serviceDescription
          notePersonal
          serviceLegaldescription
          isFixedPrice
          isActivated
          keywords
          serviceAutoAccepted
          serviceDuration
          bufferTimeBefore
          bufferTimeAfter
          ServicePrice
          currency
          onlineBooking
          serviceRating
          reviewNb
          createdAt
          updatedAt
          serviceCategoryId
          serviceSubCategoryId
          servicePostcodecityId
          owner
        }
        resourceServicesId
        resource {
          id
          ownerSelf
          firstname
          lastname
          phone
          email
          login
          webSiteAccess
          canBookOnline
          picture
          interval
          weekWorkingDays
          dayOffList
          serviceProviderResourcesId
          parameters
          createdAt
          updatedAt
          owner
        }
        resourceID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getResource = /* GraphQL */ `
  query GetResource($id: ID!) {
    getResource(id: $id) {
      id
      ownerSelf
      firstname
      lastname
      phone
      email
      login
      webSiteAccess
      canBookOnline
      picture
      interval
      weekWorkingDays
      dayOffList
      serviceProviderResourcesId
      serviceProvider {
        id
        owner
        ownerRead
        firstname
        lastname
        address1
        address2
        postcode
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        namecpy
        logocpy
        siret
        siretVerified
        addresscpy1
        addresscpy2
        postcodecpy
        postcodecitycpy {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        countrycpy {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phonecpy
        emailcpy
        consentcpy
        rcs
        legalStatus
        parameters
        answers {
          nextToken
        }
        countMonthMaxActiveService
        walletSize
        countMonthAppearInSearch
        MaxWalletReached
        banned
        logoHistory
        recordHistory
        costs {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceProviderPostcodecityId
        serviceProviderCountryId
        serviceProviderPostcodecitycpyId
        serviceProviderCountrycpyId
      }
      services {
        items {
          id
          serviceResourcesId
          resourceServicesId
          resourceID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      parameters
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listResources = /* GraphQL */ `
  query ListResources(
    $id: ID
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listResources(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerSelf
        firstname
        lastname
        phone
        email
        login
        webSiteAccess
        canBookOnline
        picture
        interval
        weekWorkingDays
        dayOffList
        serviceProviderResourcesId
        serviceProvider {
          id
          owner
          ownerRead
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          namecpy
          logocpy
          siret
          siretVerified
          addresscpy1
          addresscpy2
          postcodecpy
          phonecpy
          emailcpy
          consentcpy
          rcs
          legalStatus
          parameters
          countMonthMaxActiveService
          walletSize
          countMonthAppearInSearch
          MaxWalletReached
          banned
          logoHistory
          recordHistory
          createdAt
          updatedAt
          serviceProviderPostcodecityId
          serviceProviderCountryId
          serviceProviderPostcodecitycpyId
          serviceProviderCountrycpyId
        }
        services {
          nextToken
        }
        parameters
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getEndUser = /* GraphQL */ `
  query GetEndUser($id: ID!) {
    getEndUser(id: $id) {
      id
      owner
      ownerRead
      firstname
      lastname
      address1
      address2
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      postcode
      country {
        id
        alpha2
        name
        createdAt
        updatedAt
        owner
      }
      phone
      email
      consent
      cookieParameters
      cookieUpdateDate
      parameters
      requests {
        items {
          id
          ownerRead
          owner
          endUserRequestsId
          serviceName
          categoryID
          qna
          isFixedPrice
          isProposalSP
          datetimeExpiry
          state
          createdAt
          updatedAt
          requestCategoryId
          requestSubCategoryId
          requestPostcodecityId
        }
        nextToken
      }
      createdAt
      updatedAt
      endUserPostcodecityId
      endUserCountryId
    }
  }
`;
export const listEndUsers = /* GraphQL */ `
  query ListEndUsers(
    $id: ID
    $filter: ModelEndUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEndUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        ownerRead
        firstname
        lastname
        address1
        address2
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        postcode
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        parameters
        requests {
          nextToken
        }
        createdAt
        updatedAt
        endUserPostcodecityId
        endUserCountryId
      }
      nextToken
    }
  }
`;
export const getServiceProvider = /* GraphQL */ `
  query GetServiceProvider($id: ID!) {
    getServiceProvider(id: $id) {
      id
      owner
      ownerRead
      firstname
      lastname
      address1
      address2
      postcode
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      country {
        id
        alpha2
        name
        createdAt
        updatedAt
        owner
      }
      phone
      email
      consent
      cookieParameters
      cookieUpdateDate
      namecpy
      logocpy
      siret
      siretVerified
      addresscpy1
      addresscpy2
      postcodecpy
      postcodecitycpy {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      countrycpy {
        id
        alpha2
        name
        createdAt
        updatedAt
        owner
      }
      phonecpy
      emailcpy
      consentcpy
      rcs
      legalStatus
      parameters
      answers {
        items {
          id
          owner
          ownerRead
          serviceProviderAnswersId
          requestAnswersId
          servicePrice
          currency
          quotation
          datetimeExpiry
          datetimeBookingQuotation
          datetimeBookingJob
          noteCustomer
          notePersonal
          serviceLegaldescription
          state
          onlineBooking
          createdAt
          updatedAt
          answerReviewId
        }
        nextToken
      }
      countMonthMaxActiveService
      walletSize
      countMonthAppearInSearch
      MaxWalletReached
      banned
      logoHistory
      recordHistory
      costs {
        items {
          id
          serviceProviderCostsId
          date
          price
          cost
          invoice
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      resources {
        items {
          id
          ownerSelf
          firstname
          lastname
          phone
          email
          login
          webSiteAccess
          canBookOnline
          picture
          interval
          weekWorkingDays
          dayOffList
          serviceProviderResourcesId
          parameters
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      serviceProviderPostcodecityId
      serviceProviderCountryId
      serviceProviderPostcodecitycpyId
      serviceProviderCountrycpyId
    }
  }
`;
export const listServiceProviders = /* GraphQL */ `
  query ListServiceProviders(
    $id: ID
    $filter: ModelServiceProviderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServiceProviders(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        ownerRead
        firstname
        lastname
        address1
        address2
        postcode
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        namecpy
        logocpy
        siret
        siretVerified
        addresscpy1
        addresscpy2
        postcodecpy
        postcodecitycpy {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        countrycpy {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phonecpy
        emailcpy
        consentcpy
        rcs
        legalStatus
        parameters
        answers {
          nextToken
        }
        countMonthMaxActiveService
        walletSize
        countMonthAppearInSearch
        MaxWalletReached
        banned
        logoHistory
        recordHistory
        costs {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceProviderPostcodecityId
        serviceProviderCountryId
        serviceProviderPostcodecitycpyId
        serviceProviderCountrycpyId
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      nameTag
      countryCode
      keywords
      validated
      questions
      subCategorys {
        items {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $id: ID
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        nameTag
        countryCode
        keywords
        validated
        questions
        subCategorys {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSubCategory = /* GraphQL */ `
  query GetSubCategory($id: ID!) {
    getSubCategory(id: $id) {
      id
      categorySubCategorysId
      category {
        id
        name
        nameTag
        countryCode
        keywords
        validated
        questions
        subCategorys {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      name
      nameTag
      countryCode
      validated
      questions
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSubCategories = /* GraphQL */ `
  query ListSubCategories(
    $id: ID
    $filter: ModelSubCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        categorySubCategorysId
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        nameTag
        countryCode
        validated
        questions
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      serviceProviderID
      categoryID
      category {
        id
        name
        nameTag
        countryCode
        keywords
        validated
        questions
        subCategorys {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      subCategory {
        id
        categorySubCategorysId
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        nameTag
        countryCode
        validated
        questions
        createdAt
        updatedAt
        owner
      }
      name
      serviceDescription
      notePersonal
      serviceLegaldescription
      isFixedPrice
      isActivated
      keywords
      serviceAutoAccepted
      serviceDuration
      bufferTimeBefore
      bufferTimeAfter
      ServicePrice
      currency
      onlineBooking
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      requests {
        items {
          id
          owner
          ownerRead
          serviceProviderID
          createdAtShort
          serviceRequestsId
          requestServicesId
          createdAt
          updatedAt
        }
        nextToken
      }
      resources {
        items {
          id
          serviceResourcesId
          resourceServicesId
          resourceID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      serviceRating
      reviewNb
      createdAt
      updatedAt
      serviceCategoryId
      serviceSubCategoryId
      servicePostcodecityId
      owner
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $id: ID
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServices(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        serviceProviderID
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        serviceDescription
        notePersonal
        serviceLegaldescription
        isFixedPrice
        isActivated
        keywords
        serviceAutoAccepted
        serviceDuration
        bufferTimeBefore
        bufferTimeAfter
        ServicePrice
        currency
        onlineBooking
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        requests {
          nextToken
        }
        resources {
          nextToken
        }
        serviceRating
        reviewNb
        createdAt
        updatedAt
        serviceCategoryId
        serviceSubCategoryId
        servicePostcodecityId
        owner
      }
      nextToken
    }
  }
`;
export const serviceBySPID = /* GraphQL */ `
  query ServiceBySPID(
    $serviceProviderID: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ServiceBySPID(
      serviceProviderID: $serviceProviderID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serviceProviderID
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        serviceDescription
        notePersonal
        serviceLegaldescription
        isFixedPrice
        isActivated
        keywords
        serviceAutoAccepted
        serviceDuration
        bufferTimeBefore
        bufferTimeAfter
        ServicePrice
        currency
        onlineBooking
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        requests {
          nextToken
        }
        resources {
          nextToken
        }
        serviceRating
        reviewNb
        createdAt
        updatedAt
        serviceCategoryId
        serviceSubCategoryId
        servicePostcodecityId
        owner
      }
      nextToken
    }
  }
`;
export const getServiceRequests = /* GraphQL */ `
  query GetServiceRequests($id: ID!) {
    getServiceRequests(id: $id) {
      id
      owner
      ownerRead
      serviceProviderID
      createdAtShort
      serviceRequestsId
      service {
        id
        serviceProviderID
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        serviceDescription
        notePersonal
        serviceLegaldescription
        isFixedPrice
        isActivated
        keywords
        serviceAutoAccepted
        serviceDuration
        bufferTimeBefore
        bufferTimeAfter
        ServicePrice
        currency
        onlineBooking
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        requests {
          nextToken
        }
        resources {
          nextToken
        }
        serviceRating
        reviewNb
        createdAt
        updatedAt
        serviceCategoryId
        serviceSubCategoryId
        servicePostcodecityId
        owner
      }
      requestServicesId
      request {
        id
        ownerRead
        owner
        endUserRequestsId
        endUser {
          id
          owner
          ownerRead
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          parameters
          createdAt
          updatedAt
          endUserPostcodecityId
          endUserCountryId
        }
        services {
          nextToken
        }
        serviceName
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        qna
        isFixedPrice
        isProposalSP
        datetimeExpiry
        state
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
        requestCategoryId
        requestSubCategoryId
        requestPostcodecityId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listServiceRequests = /* GraphQL */ `
  query ListServiceRequests(
    $id: ID
    $filter: ModelServiceRequestsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServiceRequests(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        ownerRead
        serviceProviderID
        createdAtShort
        serviceRequestsId
        service {
          id
          serviceProviderID
          categoryID
          name
          serviceDescription
          notePersonal
          serviceLegaldescription
          isFixedPrice
          isActivated
          keywords
          serviceAutoAccepted
          serviceDuration
          bufferTimeBefore
          bufferTimeAfter
          ServicePrice
          currency
          onlineBooking
          serviceRating
          reviewNb
          createdAt
          updatedAt
          serviceCategoryId
          serviceSubCategoryId
          servicePostcodecityId
          owner
        }
        requestServicesId
        request {
          id
          ownerRead
          owner
          endUserRequestsId
          serviceName
          categoryID
          qna
          isFixedPrice
          isProposalSP
          datetimeExpiry
          state
          createdAt
          updatedAt
          requestCategoryId
          requestSubCategoryId
          requestPostcodecityId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const serviceRequestsBySPID = /* GraphQL */ `
  query ServiceRequestsBySPID(
    $serviceProviderID: String!
    $createdAtShort: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ServiceRequestsBySPID(
      serviceProviderID: $serviceProviderID
      createdAtShort: $createdAtShort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        ownerRead
        serviceProviderID
        createdAtShort
        serviceRequestsId
        service {
          id
          serviceProviderID
          categoryID
          name
          serviceDescription
          notePersonal
          serviceLegaldescription
          isFixedPrice
          isActivated
          keywords
          serviceAutoAccepted
          serviceDuration
          bufferTimeBefore
          bufferTimeAfter
          ServicePrice
          currency
          onlineBooking
          serviceRating
          reviewNb
          createdAt
          updatedAt
          serviceCategoryId
          serviceSubCategoryId
          servicePostcodecityId
          owner
        }
        requestServicesId
        request {
          id
          ownerRead
          owner
          endUserRequestsId
          serviceName
          categoryID
          qna
          isFixedPrice
          isProposalSP
          datetimeExpiry
          state
          createdAt
          updatedAt
          requestCategoryId
          requestSubCategoryId
          requestPostcodecityId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getServiceRequestsByServiceIdRequestId = /* GraphQL */ `
  query GetServiceRequestsByServiceIdRequestId(
    $serviceRequestsId: ID!
    $requestServicesId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getServiceRequestsByServiceIdRequestId(
      serviceRequestsId: $serviceRequestsId
      requestServicesId: $requestServicesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        ownerRead
        serviceProviderID
        createdAtShort
        serviceRequestsId
        service {
          id
          serviceProviderID
          categoryID
          name
          serviceDescription
          notePersonal
          serviceLegaldescription
          isFixedPrice
          isActivated
          keywords
          serviceAutoAccepted
          serviceDuration
          bufferTimeBefore
          bufferTimeAfter
          ServicePrice
          currency
          onlineBooking
          serviceRating
          reviewNb
          createdAt
          updatedAt
          serviceCategoryId
          serviceSubCategoryId
          servicePostcodecityId
          owner
        }
        requestServicesId
        request {
          id
          ownerRead
          owner
          endUserRequestsId
          serviceName
          categoryID
          qna
          isFixedPrice
          isProposalSP
          datetimeExpiry
          state
          createdAt
          updatedAt
          requestCategoryId
          requestSubCategoryId
          requestPostcodecityId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      ownerRead
      owner
      endUserRequestsId
      endUser {
        id
        owner
        ownerRead
        firstname
        lastname
        address1
        address2
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        postcode
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        parameters
        requests {
          nextToken
        }
        createdAt
        updatedAt
        endUserPostcodecityId
        endUserCountryId
      }
      services {
        items {
          id
          owner
          ownerRead
          serviceProviderID
          createdAtShort
          serviceRequestsId
          requestServicesId
          createdAt
          updatedAt
        }
        nextToken
      }
      serviceName
      categoryID
      category {
        id
        name
        nameTag
        countryCode
        keywords
        validated
        questions
        subCategorys {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      subCategory {
        id
        categorySubCategorysId
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        name
        nameTag
        countryCode
        validated
        questions
        createdAt
        updatedAt
        owner
      }
      qna
      isFixedPrice
      isProposalSP
      datetimeExpiry
      state
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      answers {
        items {
          id
          owner
          ownerRead
          serviceProviderAnswersId
          requestAnswersId
          servicePrice
          currency
          quotation
          datetimeExpiry
          datetimeBookingQuotation
          datetimeBookingJob
          noteCustomer
          notePersonal
          serviceLegaldescription
          state
          onlineBooking
          createdAt
          updatedAt
          answerReviewId
        }
        nextToken
      }
      createdAt
      updatedAt
      requestCategoryId
      requestSubCategoryId
      requestPostcodecityId
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $id: ID
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRequests(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerRead
        owner
        endUserRequestsId
        endUser {
          id
          owner
          ownerRead
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          parameters
          createdAt
          updatedAt
          endUserPostcodecityId
          endUserCountryId
        }
        services {
          nextToken
        }
        serviceName
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        qna
        isFixedPrice
        isProposalSP
        datetimeExpiry
        state
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
        requestCategoryId
        requestSubCategoryId
        requestPostcodecityId
      }
      nextToken
    }
  }
`;
export const getPostalCode = /* GraphQL */ `
  query GetPostalCode($id: ID!) {
    getPostalCode(id: $id) {
      id
      codeINSEE
      countryCode
      townName
      postalCode
      latitude
      longitude
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPostalCodes = /* GraphQL */ `
  query ListPostalCodes(
    $id: ID
    $filter: ModelPostalCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPostalCodes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const postalCodeByTownName = /* GraphQL */ `
  query PostalCodeByTownName(
    $townName: String!
    $postalCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostalCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postalCodeByTownName(
      townName: $townName
      postalCode: $postalCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const postalCodeByPostalCode = /* GraphQL */ `
  query PostalCodeByPostalCode(
    $postalCode: String!
    $townName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostalCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postalCodeByPostalCode(
      postalCode: $postalCode
      townName: $townName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($id: ID!) {
    getCountry(id: $id) {
      id
      alpha2
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCountries = /* GraphQL */ `
  query ListCountries(
    $id: ID
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCountries(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        alpha2
        name
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      owner
      ownerRead
      serviceProviderAnswersId
      serviceProvider {
        id
        owner
        ownerRead
        firstname
        lastname
        address1
        address2
        postcode
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        namecpy
        logocpy
        siret
        siretVerified
        addresscpy1
        addresscpy2
        postcodecpy
        postcodecitycpy {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        countrycpy {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phonecpy
        emailcpy
        consentcpy
        rcs
        legalStatus
        parameters
        answers {
          nextToken
        }
        countMonthMaxActiveService
        walletSize
        countMonthAppearInSearch
        MaxWalletReached
        banned
        logoHistory
        recordHistory
        costs {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceProviderPostcodecityId
        serviceProviderCountryId
        serviceProviderPostcodecitycpyId
        serviceProviderCountrycpyId
      }
      requestAnswersId
      request {
        id
        ownerRead
        owner
        endUserRequestsId
        endUser {
          id
          owner
          ownerRead
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          parameters
          createdAt
          updatedAt
          endUserPostcodecityId
          endUserCountryId
        }
        services {
          nextToken
        }
        serviceName
        categoryID
        category {
          id
          name
          nameTag
          countryCode
          keywords
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        subCategory {
          id
          categorySubCategorysId
          name
          nameTag
          countryCode
          validated
          questions
          createdAt
          updatedAt
          owner
        }
        qna
        isFixedPrice
        isProposalSP
        datetimeExpiry
        state
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
        requestCategoryId
        requestSubCategoryId
        requestPostcodecityId
      }
      servicePrice
      currency
      quotation
      datetimeExpiry
      datetimeBookingQuotation
      datetimeBookingJob
      noteCustomer
      notePersonal
      serviceLegaldescription
      state
      onlineBooking
      appointments {
        items {
          id
          appointmentID
          answerID
          createdAt
          updatedAt
          owner
          ownerRead
        }
        nextToken
      }
      review {
        id
        euReviewDateTime
        euReviewComment
        spRating
        spReviewDateTime
        spReviewComment
        serviceID
        serviceProviderID
        answerID
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      answerReviewId
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $id: ID
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAnswers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        ownerRead
        serviceProviderAnswersId
        serviceProvider {
          id
          owner
          ownerRead
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          namecpy
          logocpy
          siret
          siretVerified
          addresscpy1
          addresscpy2
          postcodecpy
          phonecpy
          emailcpy
          consentcpy
          rcs
          legalStatus
          parameters
          countMonthMaxActiveService
          walletSize
          countMonthAppearInSearch
          MaxWalletReached
          banned
          logoHistory
          recordHistory
          createdAt
          updatedAt
          serviceProviderPostcodecityId
          serviceProviderCountryId
          serviceProviderPostcodecitycpyId
          serviceProviderCountrycpyId
        }
        requestAnswersId
        request {
          id
          ownerRead
          owner
          endUserRequestsId
          serviceName
          categoryID
          qna
          isFixedPrice
          isProposalSP
          datetimeExpiry
          state
          createdAt
          updatedAt
          requestCategoryId
          requestSubCategoryId
          requestPostcodecityId
        }
        servicePrice
        currency
        quotation
        datetimeExpiry
        datetimeBookingQuotation
        datetimeBookingJob
        noteCustomer
        notePersonal
        serviceLegaldescription
        state
        onlineBooking
        appointments {
          nextToken
        }
        review {
          id
          euReviewDateTime
          euReviewComment
          spRating
          spReviewDateTime
          spReviewComment
          serviceID
          serviceProviderID
          answerID
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        answerReviewId
      }
      nextToken
    }
  }
`;
export const getAnswersByRequestId = /* GraphQL */ `
  query GetAnswersByRequestId(
    $requestAnswersId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAnswersByRequestId(
      requestAnswersId: $requestAnswersId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        ownerRead
        serviceProviderAnswersId
        serviceProvider {
          id
          owner
          ownerRead
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          namecpy
          logocpy
          siret
          siretVerified
          addresscpy1
          addresscpy2
          postcodecpy
          phonecpy
          emailcpy
          consentcpy
          rcs
          legalStatus
          parameters
          countMonthMaxActiveService
          walletSize
          countMonthAppearInSearch
          MaxWalletReached
          banned
          logoHistory
          recordHistory
          createdAt
          updatedAt
          serviceProviderPostcodecityId
          serviceProviderCountryId
          serviceProviderPostcodecitycpyId
          serviceProviderCountrycpyId
        }
        requestAnswersId
        request {
          id
          ownerRead
          owner
          endUserRequestsId
          serviceName
          categoryID
          qna
          isFixedPrice
          isProposalSP
          datetimeExpiry
          state
          createdAt
          updatedAt
          requestCategoryId
          requestSubCategoryId
          requestPostcodecityId
        }
        servicePrice
        currency
        quotation
        datetimeExpiry
        datetimeBookingQuotation
        datetimeBookingJob
        noteCustomer
        notePersonal
        serviceLegaldescription
        state
        onlineBooking
        appointments {
          nextToken
        }
        review {
          id
          euReviewDateTime
          euReviewComment
          spRating
          spReviewDateTime
          spReviewComment
          serviceID
          serviceProviderID
          answerID
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        answerReviewId
      }
      nextToken
    }
  }
`;
export const getMonthlyCost = /* GraphQL */ `
  query GetMonthlyCost($id: ID!) {
    getMonthlyCost(id: $id) {
      id
      serviceProviderCostsId
      serviceProvider {
        id
        owner
        ownerRead
        firstname
        lastname
        address1
        address2
        postcode
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phone
        email
        consent
        cookieParameters
        cookieUpdateDate
        namecpy
        logocpy
        siret
        siretVerified
        addresscpy1
        addresscpy2
        postcodecpy
        postcodecitycpy {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        countrycpy {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        phonecpy
        emailcpy
        consentcpy
        rcs
        legalStatus
        parameters
        answers {
          nextToken
        }
        countMonthMaxActiveService
        walletSize
        countMonthAppearInSearch
        MaxWalletReached
        banned
        logoHistory
        recordHistory
        costs {
          nextToken
        }
        resources {
          nextToken
        }
        createdAt
        updatedAt
        serviceProviderPostcodecityId
        serviceProviderCountryId
        serviceProviderPostcodecitycpyId
        serviceProviderCountrycpyId
      }
      date
      price
      cost
      invoice
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listMonthlyCosts = /* GraphQL */ `
  query ListMonthlyCosts(
    $id: ID
    $filter: ModelMonthlyCostFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMonthlyCosts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        serviceProviderCostsId
        serviceProvider {
          id
          owner
          ownerRead
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          namecpy
          logocpy
          siret
          siretVerified
          addresscpy1
          addresscpy2
          postcodecpy
          phonecpy
          emailcpy
          consentcpy
          rcs
          legalStatus
          parameters
          countMonthMaxActiveService
          walletSize
          countMonthAppearInSearch
          MaxWalletReached
          banned
          logoHistory
          recordHistory
          createdAt
          updatedAt
          serviceProviderPostcodecityId
          serviceProviderCountryId
          serviceProviderPostcodecitycpyId
          serviceProviderCountrycpyId
        }
        date
        price
        cost
        invoice
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMiniWeb = /* GraphQL */ `
  query GetMiniWeb($serviceProviderID: String!) {
    getMiniWeb(serviceProviderID: $serviceProviderID) {
      serviceProviderID
      searchkey
      name
      address1
      address2
      country {
        id
        alpha2
        name
        createdAt
        updatedAt
        owner
      }
      email
      toggleEmail
      logo
      owner
      phone
      togglePhone
      toggleAddress
      postcode
      postcodecity {
        id
        codeINSEE
        countryCode
        townName
        postalCode
        latitude
        longitude
        createdAt
        updatedAt
        owner
      }
      layoutId
      themeId
      priceRange
      togglePriceRange
      jobName
      score
      showroomDescription
      showroomPictures
      showroomPicturesLoop
      showroomBackground
      servicesDescription
      servicesBackground
      toggleMiniweb
      siret
      toggleSiret
      keywords
      firstname
      createdAt
      updatedAt
      miniWebCountryId
      miniWebPostcodecityId
    }
  }
`;
export const listMiniWebs = /* GraphQL */ `
  query ListMiniWebs(
    $serviceProviderID: String
    $filter: ModelMiniWebFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMiniWebs(
      serviceProviderID: $serviceProviderID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        serviceProviderID
        searchkey
        name
        address1
        address2
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        email
        toggleEmail
        logo
        owner
        phone
        togglePhone
        toggleAddress
        postcode
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        layoutId
        themeId
        priceRange
        togglePriceRange
        jobName
        score
        showroomDescription
        showroomPictures
        showroomPicturesLoop
        showroomBackground
        servicesDescription
        servicesBackground
        toggleMiniweb
        siret
        toggleSiret
        keywords
        firstname
        createdAt
        updatedAt
        miniWebCountryId
        miniWebPostcodecityId
      }
      nextToken
    }
  }
`;
export const miniWebBySearchkey = /* GraphQL */ `
  query MiniWebBySearchkey(
    $searchkey: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMiniWebFilterInput
    $limit: Int
    $nextToken: String
  ) {
    miniWebBySearchkey(
      searchkey: $searchkey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        serviceProviderID
        searchkey
        name
        address1
        address2
        country {
          id
          alpha2
          name
          createdAt
          updatedAt
          owner
        }
        email
        toggleEmail
        logo
        owner
        phone
        togglePhone
        toggleAddress
        postcode
        postcodecity {
          id
          codeINSEE
          countryCode
          townName
          postalCode
          latitude
          longitude
          createdAt
          updatedAt
          owner
        }
        layoutId
        themeId
        priceRange
        togglePriceRange
        jobName
        score
        showroomDescription
        showroomPictures
        showroomPicturesLoop
        showroomBackground
        servicesDescription
        servicesBackground
        toggleMiniweb
        siret
        toggleSiret
        keywords
        firstname
        createdAt
        updatedAt
        miniWebCountryId
        miniWebPostcodecityId
      }
      nextToken
    }
  }
`;
export const getAppointmentAnswers = /* GraphQL */ `
  query GetAppointmentAnswers($id: ID!) {
    getAppointmentAnswers(id: $id) {
      id
      appointmentID
      answerID
      appointment {
        id
        dateTime
        resourceID
        resourceFirstname
        resourceLastname
        serviceID
        serviceName
        serviceDuration
        enduserID
        enduserFirstName
        enduserLastname
        enduserPhone
        enduserMail
        answers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      answer {
        id
        owner
        ownerRead
        serviceProviderAnswersId
        serviceProvider {
          id
          owner
          ownerRead
          firstname
          lastname
          address1
          address2
          postcode
          phone
          email
          consent
          cookieParameters
          cookieUpdateDate
          namecpy
          logocpy
          siret
          siretVerified
          addresscpy1
          addresscpy2
          postcodecpy
          phonecpy
          emailcpy
          consentcpy
          rcs
          legalStatus
          parameters
          countMonthMaxActiveService
          walletSize
          countMonthAppearInSearch
          MaxWalletReached
          banned
          logoHistory
          recordHistory
          createdAt
          updatedAt
          serviceProviderPostcodecityId
          serviceProviderCountryId
          serviceProviderPostcodecitycpyId
          serviceProviderCountrycpyId
        }
        requestAnswersId
        request {
          id
          ownerRead
          owner
          endUserRequestsId
          serviceName
          categoryID
          qna
          isFixedPrice
          isProposalSP
          datetimeExpiry
          state
          createdAt
          updatedAt
          requestCategoryId
          requestSubCategoryId
          requestPostcodecityId
        }
        servicePrice
        currency
        quotation
        datetimeExpiry
        datetimeBookingQuotation
        datetimeBookingJob
        noteCustomer
        notePersonal
        serviceLegaldescription
        state
        onlineBooking
        appointments {
          nextToken
        }
        review {
          id
          euReviewDateTime
          euReviewComment
          spRating
          spReviewDateTime
          spReviewComment
          serviceID
          serviceProviderID
          answerID
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        answerReviewId
      }
      createdAt
      updatedAt
      owner
      ownerRead
    }
  }
`;
export const listAppointmentAnswers = /* GraphQL */ `
  query ListAppointmentAnswers(
    $filter: ModelAppointmentAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointmentAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appointmentID
        answerID
        appointment {
          id
          dateTime
          resourceID
          resourceFirstname
          resourceLastname
          serviceID
          serviceName
          serviceDuration
          enduserID
          enduserFirstName
          enduserLastname
          enduserPhone
          enduserMail
          createdAt
          updatedAt
          owner
        }
        answer {
          id
          owner
          ownerRead
          serviceProviderAnswersId
          requestAnswersId
          servicePrice
          currency
          quotation
          datetimeExpiry
          datetimeBookingQuotation
          datetimeBookingJob
          noteCustomer
          notePersonal
          serviceLegaldescription
          state
          onlineBooking
          createdAt
          updatedAt
          answerReviewId
        }
        createdAt
        updatedAt
        owner
        ownerRead
      }
      nextToken
    }
  }
`;
export const getSpBillingLogo = /* GraphQL */ `
  query GetSpBillingLogo($spId: String!, $mmyy: String!) {
    getSpBillingLogo(spId: $spId, mmyy: $mmyy)
  }
`;
export const spGetBillingMonthly = /* GraphQL */ `
  query SpGetBillingMonthly($spId: String!) {
    spGetBillingMonthly(spId: $spId)
  }
`;
export const getByCityPostal = /* GraphQL */ `
  query GetByCityPostal($search: String!) {
    getByCityPostal(search: $search)
  }
`;
