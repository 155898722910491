// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the country
// ------------------------------------------------------------------------------

import { listCountriesSimplify } from '@/graphql/kdwqueries.js'
import { API } from 'aws-amplify'
import { compare } from '@/helpers/GenericFunctions.js'

export async function fetchCountries (CountryArray) {
  // console.log('Fetch country...')
  try {
    const apiData = await API.graphql({ query: listCountriesSimplify, variables: { limit: 300 }, authMode: 'AWS_IAM' })
    // console.log(apiData)
    const countrysFromAPI = apiData.data.listCountries.items
    Promise.all(countrysFromAPI.map(async Country => {
      if (Country.name) {
        //  alert(category.name)
        CountryArray.push({ name: Country.name, id: Country.id })
      }
    }))
    CountryArray.sort(compare)
    // console.log(CountryArray)
  } catch (err) {
    console.log(err)
    // alert('Fail to load the countries')
  }
}
