<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to manage the rating provider by the endUser or ServiceProvider
// Props:
// - from: Indicate from which point of view we need to open the dialog: End User = 'EU' or Service Provider = 'SP. Default is 'EU'.
// - answerId: Id of the answer. To be used to create the comment.
// - reviewId: id of the review if it exist. If not, we put an empty string.
// - spId: ServiceProvider ID. if not, we put an empty string.
// - serviceId: ID of the service. if not, we put an empty string.
// - requestNb: this is row number in the request list built during load of EU
// - answerNb: this is the row number in the answer in the request listbuilt during the load of EU
// Events:
// - resetDialogReview: this is to ask to reset the props to the parent component
// - updateRequestListEU: this update the request list in memory when the trigger is End User front. This is containing an object with 3 elements: { requestNb: this.requestNb, answerNb: this.answerNb, reviewId: apiData.data.createReview.id }
// Example of usage:
  <kdw-dialog-review
    v-model="dialogReview"
    from="EU"
    :answer-id="answerId"
    :review-id="reviewId"
    :sp-id="ServiceProvider.id"
    :service-Id="Service.id"
    :request-nb="requestNb"
    :answer-nb="answerNb"
  />
// ------------------------------------------------------------------------------>

<template>
  <v-dialog
    v-model="dialogStatus"
    max-width="500px"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <v-card-title>
        <base-info-card
          :title="$t('reviewDialog.title')"
          color="primary"
        />
      </v-card-title>
      <v-card-text>
        <v-spacer />
        <v-rating
          v-model="spRating"
          half-increments
          color="primary"
          :readonly="from === 'SP'"
          x-large
        />
        <v-spacer />
      </v-card-text>
      <v-card-text>
        <v-textarea
          id="euReviewComment"
          v-model="euReviewComment"
          :label="$t('reviewDialog.euReviewComment')"
          auto-grow
          outlined
          :autofocus="from === 'EU'"
          :disabled="from === 'SP'"
          prepend-icon="mdi-comment-plus"
          rows="1"
        />
      </v-card-text>
      <v-card-text>
        <v-textarea
          v-if="!((from === 'EU') && (spReviewComment === ''))"
          id="spReviewComment"
          v-model="spReviewComment"
          :label="$t('reviewDialog.spReviewComment')"
          auto-grow
          :autofocus="from === 'SP'"
          outlined
          :disabled="from === 'EU'"
          prepend-icon="mdi-comment-plus"
          rows="1"
        />
      </v-card-text>
      <v-card-actions>
        <kdw-btn
          id="closeDialogReview"
          @click="closeDialog"
        >
          {{ $t('button.close') }}
        </kdw-btn>
        <v-spacer />
        <kdw-btn
          @click="saveReview()"
        >
          {{ $t('button.validateQuestion') }}
        </kdw-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'
  import { createReview as createReviewMutation, updateAnswer as updateAnswerMutation, updateReview as updateReviewMutation, updateService as updateServiceMutation } from '@/graphql/mutations'
  import { getReviewDialogReview, reviewByServiceIDComputeRatingAverage } from '@/graphql/kdwqueries'
  import { Logger, API } from 'aws-amplify'
  import { DateTime } from 'luxon'

  const logger = new Logger('Review')

  export default {
    name: 'KDWReviewDialog',

    mixins: [Heading],

    props: {
      value: {
        type: Boolean,
        required: true,
      },
      from: {
        type: String,
        default: 'EU',
      },
      answerId: {
        type: String,
      },
      reviewId: {
        type: String,
        default: '',
      },
      spId: {
        type: String,
        default: '',
      },
      serviceId: {
        type: String,
        default: '',
      },
      requestNb: {
        type: Number,
        default: 0,
      },
      answerNb: {
        type: Number,
        default: 0,
      },
    },

    data: () => {
      return {
        dialogStatus: false,
        spRating: 5,
        euReviewComment: '',
        spReviewComment: '',
      }
    },
    watch: {
      value: function (newValue) {
        // console.log('from:', this.from, ' -New value dialog Review:', newValue, ' & answerId=', this.answerId, ' & reviewId=', this.reviewId, '& spId=', this.spId, '& serviceId=', this.serviceId)
        if (this.reviewId) {
          try {
            // We are opening the existing review
            API.graphql({ query: getReviewDialogReview, variables: { id: this.reviewId }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
              .then((apiDataReview) => {
                // console.log('apiDataReview:')
                // console.log(apiDataReview)
                this.euReviewComment = apiDataReview.data.getReview.euReviewComment
                this.spRating = apiDataReview.data.getReview.spRating
                this.spReviewComment = apiDataReview.data.getReview.spReviewComment
              })
          } catch (err) {
            logger.error(err)
          }
        }
        this.dialogStatus = newValue
      },
    },

    created: function () {
      this.dialogStatus = false
    },
    methods: {
      emitResult () {
        this.$emit('input', this.dialogStatus)
        this.$emit('resetDialogReview')
      },
      closeDialog () {
        this.dialogStatus = false
        this.spRating = 5
        this.euReviewComment = ''
        this.spReviewComment = ''
        this.emitResult()
      },
      async saveReview () {
        console.log('answerId=', this.answerId, ' & reviewId=', this.reviewId, '& spId=', this.spId, '& serviceId=', this.serviceId)
        var myReview = {}
        myReview.euReviewComment = this.euReviewComment
        if (this.from === 'SP') {
          // console.log('We are adding  the sp data', this.spReviewComment)
          myReview.spReviewComment = this.spReviewComment
          myReview.spReviewDateTime = DateTime.now().setZone('utc').toString()
        }
        myReview.spRating = this.spRating
        myReview.answerID = this.answerId
        if (this.spId) {
          myReview.serviceProviderID = this.spId
        }
        if (this.serviceId) {
          myReview.serviceID = this.serviceId
        }
        myReview.euReviewDateTime = DateTime.now().setZone('utc').toString()
        // console.log('myReview:')
        // console.log(myReview)
        try {
          if (this.reviewId) {
            // We just need to update the review
            myReview.id = this.reviewId
            await API.graphql({ query: updateReviewMutation, variables: { input: myReview }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          } else {
            // We create the review as it is new review
            const apiData = await API.graphql({ query: createReviewMutation, variables: { input: myReview }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            // console.log('apiData from saveReview:')
            // console.log(apiData)
            // We update the ID of the answer to make the link.
            var answerToUpdate = {}
            answerToUpdate.id = this.answerId
            answerToUpdate.answerReviewId = apiData.data.createReview.id
            await API.graphql({ query: updateAnswerMutation, variables: { input: answerToUpdate }, authMode: 'AWS_IAM' })
            if (this.from === 'EU') this.$emit('updateRequestListEU', { requestNb: this.requestNb, answerNb: this.answerNb, reviewId: apiData.data.createReview.id })
          }
          // We need to adjust the service rating and review number at service level.
          if (this.from === 'EU') {
            const serviceId = this.serviceId
            var serviceProviderID = this.spId
            API.graphql({ query: reviewByServiceIDComputeRatingAverage, variables: { serviceID: this.serviceId }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
              .then((apiDataReviewAverage) => {
                // console.log('apiDataReviewAverage:')
                // console.log(apiDataReviewAverage)
                let average = 0
                if (apiDataReviewAverage.data.reviewByServiceID.items.length > 0) {
                  for (var i = 0; i < apiDataReviewAverage.data.reviewByServiceID.items.length; i++) {
                    average = average + apiDataReviewAverage.data.reviewByServiceID.items[i].spRating
                  }
                  average = average / apiDataReviewAverage.data.reviewByServiceID.items.length
                  average = Math.round(average * 10) / 10
                }
                // console.log('average:', average)
                // We upgrade the data in service rating
                var myUpdateService = {}
                if (serviceId === '') return
                myUpdateService.id = serviceId
                if (serviceProviderID === '') return
                myUpdateService.serviceProviderID = serviceProviderID
                myUpdateService.serviceRating = average
                myUpdateService.reviewNb = apiDataReviewAverage.data.reviewByServiceID.items.length
                // console.log('myUpdateService:')
                // console.log(myUpdateService)
                API.graphql({ query: updateServiceMutation, variables: { input: myUpdateService }, authMode: 'AWS_IAM' })
              })
          }
        } catch (e) {
          console.log(e)
        }
        this.closeDialog()
      },
    },
  }
</script>
