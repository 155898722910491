<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to display a general info to the end user, or a basic info like deleting or confirming something.
// Props:
// - userTextTag: Tag of the Text to be shared with the user (question or information).Mandatory
// - type: 'Info', 'Delete', 'Confirm' - Nature of the dialog box. Default is 'Info'.
// - title: title in case of Info.
// Emit:
//   - dialogGeneralDeleteConfirm: Object returned when the result is confirmed
       {
        type: props type passed
        result: 'OK'   // to confirm that the action is approved by the user. This is used only in case of sucessfull result.
       }
// Example of usage:
  <kdw-dialog-general-info-delete-confirm
    v-model="dialogCardInfo"
    type="Info"
    user-text-tag="miniweb.mytext"
  />

// ------------------------------------------------------------------------------>

<template>
  <v-dialog
    max-width="500px"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <!-- This is the case in case of Info-->
      <v-card-title v-if="type === 'Info'">
        <base-info-card
          :title="title"
          color="primary"
        />
      </v-card-title>
      <v-card-text
        v-if="type === 'Info'"
        style="white-space: normal"
        class="text-h5"
      >
        {{ $t(userTextTag) }}
      </v-card-text>
      <!-- This is case of question -->
      <v-card-text>
        <kdw-avatar-card
          v-if="type === 'Delete' || type === 'Confirm'"
          icon="mdi-help-circle-outline"
          :outlined="false"
          color="transparent"
          :title="userTextTag"
          horizontal
          space="0"
        />
        <!--:title="userText"-->
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <kdw-btn
          id="deleteDialog"
          text
          @click="closeDialog()"
        >
          {{ $t('button.cancel') }}
        </kdw-btn>
        <v-spacer />
        <kdw-btn
          v-if="type === 'Delete' || type === 'Confirm'"
          id="deleteOrConfim"
          @click="deleteOrConfirmRequest"
        >
          {{ ((type === 'Delete')) ? $t('button.deleteRequestEU') : $t('button.validateQuestion') }}
        </kdw-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DialogGeneralInfoDeleteConfirm',

    props: {
      type: {
        type: String,
        default: 'Info',
      },
      userTextTag: {
        type: String,
        default: '',
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
    },

    data: () => {
      return {
        dialogStatus: false,
      }
    },

    created: function () {
      this.dialogStatus = false
    },
    methods: {
      emitResult () {
        this.$emit('input', this.dialogStatus)
      },
      closeDialog () {
        this.dialogStatus = false
        this.emitResult()
      },
      deleteOrConfirmRequest () {
        // We emit the result:
        this.$emit('dialogGeneralDeleteConfirm', { type: this.type, result: 'OK' })
        // Need to decide what to emit.
        this.closeDialog()
      },
    },
  }
</script>
