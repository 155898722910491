<template>
  <div>
    <miniweb-template-basic
      :data-template="data"
    />
    <miniweb-template-elegant
      :data-template="data"
    />
  </div>
</template>
<script>
  import { Component, Vue } from 'vue-property-decorator'
  import { prefetchData } from '@/services/miniweb/service'
  import { navigate } from '@/helpers/Navigation'
  import { isObjectEmpty } from '@/helpers/GenericFunctions'

  @Component({ name: 'MiniwebRouterComponent' })
  class MiniwebRouterComponent extends Vue {
    data = {}
    searchKey = ''
    // mixinsResult = {}

    created () {
      // console.log(this.$router)
      console.log('SearchKey in router Component:', this.$router.searchKey)
      if (this.$router && this.$router.searchKey) {
        this.searchKey = this.$router.searchKey.toLowerCase()
        prefetchData(this.$router.searchKey.toLowerCase())
          .then((data1) => {
            this.data = data1
            // console.log('this.data from prefetch')
            // console.log(data1)
            // If the result is empty, it means that we have to redirect to 404.
            if (!this.data.template || isObjectEmpty(this.data.template)) {
              navigate(this, 'UserFourOhFour')
            }
            if (this.data.template && this.data.template.toggleMiniweb !== null && this.data.template.toggleMiniweb !== undefined && this.data.template.toggleMiniweb === false) {
              navigate(this, 'UserFourOhFour')
            }
          })
      } else console.log('Error in the router.......')
    }
  }
  export default MiniwebRouterComponent
</script>
