<template>
  <div>
    <!--
    <transition
      name="bounce"
      appear
    >
      <div
        v-if="visible"
        class="ui message success"
      >
        Message+
      </div>
    </transition>
    <v-btn @click="toggle">Toggle</v-btn>
    -->
    <v-card
      elevation="8"
      flat
      rounded
      :class="position"
    >
      <v-spacer />
      <miniweb-btn-deco-hover
        :mdi="mdi"
        color="white"
        color-hover="rgba(157, 0, 255, 1)"
        avatar-color="rgba(73, 2, 124,1)"
      />
      <v-spacer />
      <v-card-title>
        <v-spacer />
        <!-- <v-hover v-slot="{ hover }"> -->
        <!-- WORKING BEFORE
          <transition
          name="slide-fade"
          appear
        >
          <div
            v-if="show"
            @mouseover="show = false"
            @mouseleave="show = true"
          >
            {{ title }}
          </div>
        </transition>
        -->
        <v-scroll-x-transition>
          <div
            v-if="show"
            @mouseover="triggerReappear"
          >
            {{ title }}
          </div>
        </v-scroll-x-transition>
        <!-- </v-hover> -->
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-spacer />
        {{ subTitle }}
        <v-spacer />
      </v-card-text>
      <!--
      <v-card-text>
        <transition name="slide-fade">
          <div>
            <img
              src="@/assets/favicon-16x16.png"
              @mouseover="show = false"
              @mouseleave="show = true"
              v-show="show"
            />
          </div>
        </transition>
        <v-btn @click="show = !show">Show</v-btn>
      </v-card-text>
      -->
    </v-card>
  </div>
</template>

<script>

  export default {
    name: 'BtnDecoHover',

    props: {
      position: {
        type: String,
        default: 'text-center ma-10',
      },
      title: {
        type: String,
        default: '',
      },
      subTitle: {
        type: String,
        default: '',
      },
      mdi: {
        type: String,
        default: 'mdi-check-bold',
      },
    },
    data: () => ({
      visible: true,
      show: true,
    }),
    methods: {
      triggerReappear () {
        this.show = false
        setTimeout(() => {
          this.show = true
        }, 800)
      },
      /*
      toggle () {
        this.visible = !this.visible
      },
      */
    },
  }
</script>
<style>
  /*
  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s, transform 1s
  }
  .fade-enter, .fade-leave-active {
    opacity: 0.5;
    transformation: translateX(20px)
  }
  */
  /*
  .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }
  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0)
    }
    50% {
      transform: scale(1.25)
    }
    100% {
      transform: scale(1)
    }
  }

  .anim-enter-active, .anim-leave-active {
    transition: transform 0.5s ease, opacity 0.5s ease;
  }

  .anim-enter, .anim-leave-to {
    transform: translateX(-100px) rotateZ(90deg);
    opacity: 0
  }

  .anim-enter-to, .anim-leave {
    transform: translateX(0px) rotateZ(0deg);
    opacity: 1
  }

  .anim1-enter-active {
    transition: transform 1s ease, opacity 1s ease;
  }
  .anim1-enter-from {
    transform: translateX(-100px) rotateZ(90deg);
    opacity: 0
  }
  .anim1-enter-to {
    transform: translateX(0px) rotateZ(0deg);
    opacity: 1
  }

  .fade1-enter-active, .fade1-leave-active {
    transition: opacity 2.5s ease-out;
  }

  .fade1-enter, .fade1-leave-to {
    opacity: 0;
  }

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(50px);
  opacity: 0;
}
*/
</style>
