<!------------------------------------------------------------------------------
// KIDIWI Digital property
// Component to trigger a slider which is increasing automatically. The start of the increment is triggered by passing on top of the slide or by an changing the property "parentTrigger" to true.
// Props:
//  - color: color of the slider.
//  - init: default  value of the slider (default is 0)
//  - max: Maximum value of the slider (default is 100). The auto-increment will reach this maximim
//  - speed: how quickly the slider will increase. Default is 50 ms.
//  - label: label of the slider. Default is ''
//  - parentTrigger: boolean which is allowing to trigger to slider (default is false).
// Example of usage:
      <miniweb-auto-slider />
// ------------------------------------------------------------------------------>
<template>
  <v-hover v-slot="{ hover }">
    <v-slider
      v-model="slider"
      thumb-label="always"
      :color="color"
      :label="hover? labelAndTrigger() : label"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot />
    </v-slider>
  </v-hover>
</template>

<script>
  export default {
    name: 'BtnDecoHover',

    props: {
      color: {
        type: String,
        default: 'primary',
      },
      init: {
        type: Number,
        default: 0,
      },
      max: {
        type: Number,
        default: 100,
      },
      speed: {
        type: Number,
        default: 50,
      },
      label: {
        type: String,
        default: '',
      },
      parentTrigger: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      slider: 0,
      immediate: false,
    }),
    watch: {
      slider: {
        handler (value) {
          this.autoIncrementSlider(value)
        },
        immediate: false, // This ensures the watcher is triggered upon creation. This is to start the handler function rigth away.
      },
      parentTrigger: function (value) {
        // console.log('triggered by parent')
        this.autoIncrementSlider(value)
      },
    },
    created () {
      this.slider = this.init
      // console.log('this.slider')
      // console.log(this.slider)
      // console.log(this.max)
    },
    methods: {
      labelAndTrigger () {
        this.autoIncrementSlider(this.slider)
        return this.label
      },
      autoIncrementSlider (value) {
        if (value < this.max) {
          setTimeout(() => {
            this.slider++
          }, this.speed)
        }
      },
    },
  }
</script>
