// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the categories and Sub categories library
// ------------------------------------------------------------------------------

import { getUserSub } from '@/services/User'
import { listServicesSimplify, listCategoriesCheck } from '@/graphql/kdwqueries'
import { Logger, API } from 'aws-amplify'
import { createCategory as createCategoryMutation } from '@/graphql/mutations'
import { listCategories, getSubCategory, getCategory } from '@/graphql/queries'
import { compare } from '@/helpers/GenericFunctions'
import i18n from '@/plugins/i18n.js'
import { formatCategoryOrSubCategoryName } from '@/helpers/Formatter'

const logger = new Logger('Category')

export async function fetchCategoryListForSP (myCategoryArray) {
    // console.log('fetchCategoryListForSP')
    try {
      const myFilter = {
        serviceProviderID: {
          eq: await getUserSub(),
        },
      }
      var nextTokenStart = 'start'
      var listFromAPI, found, i, apiData
      while (nextTokenStart !== null) {
        if (nextTokenStart === 'start') {
          apiData = await API.graphql({ query: listServicesSimplify, variables: { filter: myFilter, limit: 4000 }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        } else {
          apiData = await API.graphql({ query: listServicesSimplify, variables: { filter: myFilter, limit: 4000, nextToken: nextTokenStart }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        }
        // console.log('apiData:')
        // console.log(apiData)
        listFromAPI = apiData.data.listServices.items
        // console.log(listFromAPI)
        nextTokenStart = apiData.data.listServices.nextToken
        if ((listFromAPI.length > 0)) {
            Promise.all(listFromAPI.map(async service => {
                // console.log(service)
                found = false
                i = 0
                // We search to check if the category already exist in the array
                while ((found === false) && (i < myCategoryArray.length)) {
                    if (service.category.id === myCategoryArray[i].id) {
                        found = true
                    }
                    i++
                }
                if (found === false) {
                  myCategoryArray.push({ id: service.category.id, name: service.category.name, nameTag: service.category.nameTag })
                }
              }))
            }
        }
    } catch (err) {
        logger.error(err)
    }
}

export async function fetchCategory (myCategories) {
    try {
      const apiData = await API.graphql({ query: listCategories, authMode: 'AWS_IAM' })
      const categoryFromAPI = apiData.data.listCategories.items
      Promise.all(categoryFromAPI.map(async category => {
        if (category.name) {
          myCategories.push({ name: formatCategoryOrSubCategoryName(category.name, category.countryCode, category.nameTag), id: category.id })
        }
      }))
      myCategories.sort(compare)
    } catch (err) {
      logger.error(err)
    }
  }
  /*
  export async function fetchCategoryWithoutPromise (myCategories) {
    try {
      const apiData = await API.graphql({ query: listCategories, authMode: 'AWS_IAM' })
      const categoryFromAPI = apiData.data.listCategories.items
      console.log('categoryFromAPI:')
      console.log(categoryFromAPI)
      console.log('categoryFromAPI[0]:', categoryFromAPI[0].name)
      for (var i = 0; i < categoryFromAPI.length; i++) {
        if (categoryFromAPI[i].name) {
          myCategories.push({ name: formatCategoryOrSubCategoryName(categoryFromAPI[i].name, categoryFromAPI[i].countryCode, categoryFromAPI[i].nameTag), id: categoryFromAPI[i].id })
        }
      }
      myCategories.sort(compare)
    } catch (err) {
      logger.error(err)
    }
  }
  */
  export async function fetchACategoryReturnQuestion (catID, locale) {
    // logger.info('fetchACategoryReturnQuestion')
    try {
      // logger.info('fetchACategoryReturnQuestion- Pass 1')
      const myNewCategory = await API.graphql({ query: getCategory, variables: { id: catID }, authMode: 'AWS_IAM' })
      // logger.info('fetchACategoryReturnQuestion- Pass 2')
      // console.log('myNewCategory')
      // console.log(myNewCategory)
      if (locale === 'fr') {
        return myNewCategory.data.getCategory.questions[0]
      } else {
        if ((locale === 'en') && (myNewCategory.data.getCategory.questions.length > 1)) {
          return myNewCategory.data.getCategory.questions[1]
        } else {
          return ''
        }
      }
    } catch (err) {
      logger.error(err)
      alert(i18n.t('categoryLib.alertFailFetchCat'))
    }
  }

  export async function fetchASubCategoryReturnQuestion (subCatID, locale) {
    try {
      const myNewSubCategory = await API.graphql({ query: getSubCategory, variables: { id: subCatID }, authMode: 'AWS_IAM' })
      // console.log('myNewSubCategory:')
      // console.log(myNewSubCategory)
      if (locale === 'fr') {
        return myNewSubCategory.data.getSubCategory.questions[0]
      } else {
        if ((locale === 'en') && (myNewSubCategory.data.getSubCategory.questions.length > 1)) {
          return myNewSubCategory.data.getSubCategory.questions[1]
        } else {
          return ''
        }
      }
    } catch (err) {
      logger.error(err)
      logger.error('fail to fetch a sub category ')
    }
  }

  export async function fetchASubCategory (subCatID) {
    try {
      // console.log('subCatID:' + subCatID)
      if ((subCatID === '') || (subCatID === undefined)) {
        return ''
      }
      const myNewSubCategory = await API.graphql({ query: getSubCategory, variables: { id: subCatID }, authMode: 'AWS_IAM' })
      // console.log(myNewSubCategory)
      if ((myNewSubCategory.data.getSubCategory.name === undefined) || (myNewSubCategory.data.getSubCategory.name === '')) {
        return myNewSubCategory.data.getSubCategory.name
      }
      return formatCategoryOrSubCategoryName(myNewSubCategory.data.getSubCategory.name, myNewSubCategory.data.getSubCategory.countryCode, myNewSubCategory.data.getSubCategory.nameTag)
    } catch (err) {
      logger.error(err)
    }
  }

  async function createCategory1 (categoryName, keywords) {
    try {
      const myCategory = { name: categoryName, validated: true, keywords: keywords, questions: [''] }
      const myNewCategory = await API.graphql({ query: createCategoryMutation, variables: { input: myCategory }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      console.log('Category created:' + categoryName)
      return myNewCategory
    } catch (err) {
      logger.error(err)
      alert(i18n.t('categoryLib.alertFailCreateCat'))
    }
  }

  /*
  function checkCatSubCatNameMatching (verifCategoryName, nameTag, categoryName, countryCode) {
    if (nameTag) {
      // console.log('verifCategoryName:', verifCategoryName.toLowerCase(), '-', (i18n.t(nameTag)).toLowerCase(), i18n.locale)
      if (verifCategoryName.toLowerCase() === (i18n.t(nameTag)).toLowerCase()) return true
    } else {
      if ((i18n.locale === countryCode) && (verifCategoryName.toLowerCase() === categoryName.toLowerCase())) return true
    }
    return false
  }
  */
  // Check if Cat name & sub cat name exist in fact
  /*
  export async function checkIfCategoryNameExist (categoryName, subcategoryName, CheckCatName) {
    try {
      if (!categoryName) return
      var nextTokenStart = 'start'
      var apiData
      var listCat
      var listSubCat
      var i = 0
      var j = 0
      console.log('categoryName: ', categoryName, subcategoryName)
      while (nextTokenStart !== null) {
        if (nextTokenStart === 'start') {
          apiData = await API.graphql({ query: listCategoriesCheck, variables: { limit: 4000 }, authMode: 'AWS_IAM' })
        } else {
          apiData = await API.graphql({ query: listCategoriesCheck, variables: { limit: 4000, nextToken: nextTokenStart }, authMode: 'AWS_IAM' })
        }
        nextTokenStart = apiData.data.listCategories.nextToken
        listCat = apiData.data.listCategories.items
        // console.log('list categories:', pointerObject.$i18n.locale)
        // console.log(listCat)
        for (i = 0; i < listCat.length; i++) {
          // 2 cases:
          // 1. Case of Subcategory is empty --> We check the category only
          if (CheckCatName && !subcategoryName) {
            // We check the category name only in all the case as we do not want to create a new one identical even in combinaison.
            if (checkCatSubCatNameMatching(categoryName, listCat[i].nameTag, listCat[i].name, listCat[i].countryCode)) return true
          }

          // 2. Case we have both a category name and subcategory name
          if (categoryName && subcategoryName) {
            // We need to check that category name & subcategory name are matching
            if (!checkCatSubCatNameMatching(categoryName, listCat[i].nameTag, listCat[i].name, listCat[i].countryCode)) continue
            listSubCat = listCat[i].subCategorys.items
            // console.log('list subcategories:', pointerObject.$i18n.locale, i)
            // console.log(listSubCat)
            for (j = 0; j < listSubCat.length; j++) {
              if (checkCatSubCatNameMatching(subcategoryName, listSubCat[j].nameTag, listSubCat[j].name, listSubCat[j].countryCode)) return true
            }
          }
        }
      }
      // console.log('categoryName before return normal: ', categoryName)
      return false
    } catch (err) {
      logger.error(err)
      // console.log('categoryName before return error: ', categoryName)
      return true
    }
  }
  */
  /*
  // Return Bolean if the name of the category from the CategoryTable exist
  export async function isCatNameExist (myCategoriesArray, catID) {
    // console.log(myCategoriesArray)
    for (var i = 0; i < myCategoriesArray.length; i++) {
      if (catID === myCategoriesArray[i].id) {
        Promise.all(myCategoriesArray[i].name).then((value) => {
          return value
        })
        //  .then((result) => {
        //    return result.data
        //  })
      }
    }
    return ''
  }
  */

  export async function createAllCategories () {
    try {
      createCategory1('Traitement nuisible', ['Rat', 'Souris', 'Fourmis', 'Blatte', 'Cafards', 'Phyto', 'Ecologique', 'Entretien', 'Désynfection'])
      createCategory1('Ascenseur', ['Monte charge', 'Etage', 'Porte Automatique'])
      createCategory1('Démolition évacuation', ['Gravas', 'Massue', 'Camion', 'Sac a gravats', 'Décharge', 'Marteau piqueur'])
      createCategory1('Diagnostic', ['Immobilier', 'Amiante', 'Tableau électrique', 'Différentiel', 'Termite', 'Thermique', 'Risque naturel', 'DPE'])
      createCategory1('Cuisine', ['Cuisiniste', 'Meuble en kit', 'Meuble', 'Frigidere', 'Plaque', 'Vitroceramique', 'Four', 'Micro onde', 'Cuisine equipé', 'Cuisine sur mesure', 'Hotte aspirante', 'Cuisiniere induction', 'Cuisiniere piano'])
      createCategory1('Sécurité', ['Alarme', 'Système', 'Video surveillance', 'Surveillance', 'Garde du corps', 'Sécurité rapprochée', 'Interphone', 'Visiophone', 'Camera', 'Gaz défense', 'defense'])
      createCategory1('Nettoyage', ['Ménage', 'Entretien', 'Désynfection'])
      // --> removed: createCategory1('Salle de bain - Sanitaire', ['Douche', 'Vasque', 'WC', 'Bidet', 'Robinnetterie', 'Paroi de douche', 'Baignoire', 'Miroir', 'Meuble SDB', 'WC suspendu', 'Accessoires sanitaires'])
      createCategory1('Sol carrelage', ['Carrelage', 'carreleur', 'Carreau', 'Gres', 'Travertin', 'Parquet', 'Ardoise', 'Bois', 'Chene', 'Stratifié', 'Gerflex', 'Moquette', 'Marbre', 'Coco'])
      createCategory1('Platre', ['Carreau de platre', 'Placo', 'Enduit', 'platrier'])
      createCategory1('Chauffage', ['Radiateur', 'Climatisation', 'Climatiseur', 'Inertie', 'Fluide', 'Pompe a chaleur', 'Solaire'])
      createCategory1('Contruction - Renovation - Maison', ['Parpain', 'Ferraillage', 'Agglo', 'Béton', 'Ciment', 'Tuile', 'Gouttière', 'Porte', 'Platre', 'Bardage', 'Echaffaudage', 'Cloture'])
      createCategory1('Isolation', ['Laine de verre', 'Ouate', 'Isolant'])
      createCategory1('Fenetre - Porte', ['Menuiserie', 'Uisserie', 'Porte', 'Fenetre', 'Porte de garage', 'Porte de service', 'Porte d’entrée', 'Porte blindée', 'PVC', 'Aluminium'])
      createCategory1('Couverture - toiture', ['Tuile', 'Photovoltaique', 'Charpente'])
      createCategory1('Déménagement', ['Déménagement'])
      createCategory1('Ebenesterie', ['Boiserie', 'Fenetre', 'Meuble', 'Cusine', 'Porte', 'Fenetre', 'Coupe feu', 'Trappe'])
      createCategory1('Peinture', ['Apres', 'Sous couche', 'Pinceau', 'Rouleau', 'Lasure', 'Vernis', 'Peinture à l’eau'])
      createCategory1('Jardin - Extérieur', [])
      createCategory1('Climatisation', ['Reversible', 'split'])
      createCategory1('Plomberie', ['Tuyau raccordement', 'Robinetterie', 'WC', 'Débouchage', 'Sanitaire', 'Curage', 'Fuite eau', 'Douche', 'Vasque', 'WC', 'Bidet', 'Robinnetterie', 'Paroi de douche', 'Baignoire', 'Miroir', 'Meuble SDB', 'WC suspendu', 'Accessoires sanitaires'])
      createCategory1('Electricité', ['Tableau électrique', 'Différentiel', 'Ampoule', 'Led', 'Plombs', 'Prise', 'Interrupteur', 'Prise de terre', 'Spot'])
      createCategory1('Serrurier', ['Cle', 'Clef', 'Serrure', 'Porte'])
      createCategory1('Bricolage', ['Marteau', 'Vis', 'Tourne-vis', 'clou', 'Réparer', 'Fixer'])
    } catch {
      console.log('Fail this list of categories ')
    }
  }
