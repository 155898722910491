class QnA {
  basicQuestionFR = `{ "countryCode":"fr", "question":[
            {"id": "basicfr0", 
             "title":"Quel est l’urgence de votre projet?", 
             "mandatory":true, 
             "tag":"", 
             "type": "OneSelect",
             "default": "Pas de date prévue", 
             "answers": [
                {"icon": null, "text": "Urgent"},
                {"icon": null,"text": "Pas de date prévue"},
                {"icon": null,"text": "Dans moins de 2 semaines"},
                {"icon": null,"text": "Dans moins d’un mois"},
                {"icon": null,"text": "Dans moins de 6 mois"}
              ]},
            {"id": "basicfr1", 
             "title":"Présenter les détails de votre projet?", 
             "mandatory":true, 
             "tag":"", 
             "type": "InputText",
             "default": null, 
             "answers": []},
            {"id": "basicfr2", "title":"Donner un nom à votre projet?", "mandatory":true, "tag":"name", "type": "InputText","default": null, "answers": []},
            {"id": "basicfr3", "title":"Voulez-vous donner la possibilité a d’autres société de vous faire des propositions?", "mandatory":true, "tag":"additional", "type": "OneSelect","default": "Oui", "answers": [{"icon": null,"text": "Oui"}, {"icon": null,"text": "Non"}]}
          ]}`

  basicQuestionEN = `{ "countryCode":"en", "question":[
            {"id": "basicen0", 
             "title":"What is the expected timeline for this project?", 
             "mandatory":true, 
             "tag":"", 
             "type": "OneSelect",
             "default": "No planned date", 
             "answers": [
                {"icon": null, "text": "Urgent"},
                {"icon": null,"text": "No planned date"},
                {"icon": null,"text": "In less than 15 days"},
                {"icon": null,"text": "In less than 1 month"},
                {"icon": null,"text": "In less than 6 months"}
              ]},
            {"id": "basicen1", 
             "title":"Can you please share some details about your project?", 
             "mandatory":true, 
             "tag":"", 
             "type": "InputText",
             "default": null,
             "answers": []},
            {"id": "basicen2", "title":"Propose a name for your project?", "mandatory":true, "tag":"name", "type": "InputText","default": null, "answers": []},
            {"id": "basicen3", "title":"Are you allowing any service provider to share with your quotation about your service request?", "mandatory":true, "tag":"additional", "type": "OneSelect","default": "Yes", "answers": [{"icon": null,"text": "Yes"}, {"icon": null,"text": "No"}]}
          ]}`

  OFFSET = 1
  givenAnswers = null
  questions = null
  question = null
  index = this.OFFSET - 1
  postalCodeId = ''

  getQuestion () {
    // get current question + few commodities
    // console.log('fake index is ' + this.index + ' and real one is ' + (this.index - this.OFFSET))
    this.question = this.questions.question[this.index - this.OFFSET]
    this.question.index = this.index
    this.question.hasNext = true
    this.question.hasPrevious = (this.index > 1)
    if (this.index === this.questions.question.length + this.OFFSET - 1) {
      this.question.hasNext = false
    }

    if (this.givenAnswers && this.question.id in this.givenAnswers) {
      this.question.givenAnswer = this.givenAnswers[this.question.id]
    } else {
      this.question.givenAnswer = null
    }
    return this.question
  }

  forward (n = null) {
    // console.log(n)
    if (n && (n < this.OFFSET || n > this.questions.question.length + this.OFFSET)) {
      // trying to go beyond limits
      return null
    } else if (this.index - this.OFFSET >= this.questions.question.length) {
      // trying to go beyond the end
      return null
    }
    if (n) {
      this.index = n
    } else {
      this.index++
    }
    return this.getQuestion()
  }

  backward (n = null) {
    if (n && (n < this.OFFSET || n > this.questions.question.length + this.OFFSET)) {
      // trying to go beyond limits
      return null
    } else if (this.index === this.OFFSET) {
      // trying to go beyond the beginning
      return null
    }
    if (n) {
      this.index = n
    } else {
      this.index--
    }
    return this.getQuestion()
  }

  fetchQuestions (pickedItem, locale) {
    // console.log('pickedItem:')
    // console.log(pickedItem)
    if (pickedItem.isFixedPrice === false) {
        if (pickedItem.question !== '') {
          // the category have a dedicated list of questions
          this.questions = JSON.parse(pickedItem.question)
        } else {
          // this is the basic list of questions
          // we are checking which language to fetch for the questions
          if (locale === 'fr') {
            this.questions = JSON.parse(this.basicQuestionFR)
          }
          if (locale === 'en') {
            this.questions = JSON.parse(this.basicQuestionEN)
          }
        }
    }
    return this.questions
  }

  storeAnswers (answer) {
    if (this.givenAnswers === null) {
      this.givenAnswers = {}
    }
    if (!(this.question.id in this.givenAnswers)) {
      this.givenAnswers[this.question.id] = {}
    }

    this.question.givenAnswer = answer
    // console.log('givenAnswer is ' + this.question.givenAnswer)
    this.givenAnswers[this.question.id] = answer
  }

  clearAnswers () {
    this.givenAnswers = null
    if (this.questions) {
      for (var q of this.questions.question) {
        q.givenAnswer = null
      }
    }
  }

  getAnswers () {
    return this.givenAnswers
  }

  getQuestions () {
    return this.questions
  }

  getReport () {
    const report = {
      date: new Date(),
      countryCode: this.questions.countryCode,
      questions: [],
    }
    for (var q of this.questions.question) {
      report.questions.push({
        question: q.title,
        tag: q.tag,
        givenAnswer: q.givenAnswer,
      })
    }

    return report
  }
}

export default QnA
