// Automatically loads and bootstraps files
// in the "./src/components/base" and "./src/components/kdw" folders.

// Imports
import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import { Logger } from 'aws-amplify'
const logger = new Logger('ComponentLoader')

const loadAndBoostrapComponents = (folder, requireComponent) => {
  for (const file of requireComponent.keys()) {
    const componentConfig = requireComponent(file)
    const name = file
      .replace(/index.js/, '')
      .replace(/^\.\//, '')
      .replace(/\.\w+$/, '')
    const componentName = upperFirst(camelCase(name))
    const prefix = upperFirst(camelCase(folder))

    Vue.component(`${prefix}${componentName}`, componentConfig.default || componentConfig)
    logger.info(`Loaded component named ${prefix}${componentName}.`)
  }
}

// https://webpack.js.org/guides/dependency-management/#requirecontext
// Warning: The arguments passed to require.context must be literals! .... hate you
const requireBaseComponents = require.context('@/components/base/', true, /\.vue$/)
const requireKdwComponents = require.context('@/components/kdw/', true, /\.vue$/)
const requireMiniwebComponents = require.context('@/components/kdw/miniweb/', true, /\.vue$/)
loadAndBoostrapComponents('base', requireBaseComponents)
loadAndBoostrapComponents('kdw', requireKdwComponents)
loadAndBoostrapComponents('miniweb', requireMiniwebComponents)
