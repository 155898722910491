import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
// import VueMeta from 'vue-meta'

import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n'

import './plugins'
import './services'

Vue.use(Vuetify)
// Vue.forceUpdate()
/*
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  // attribute: 'data-vue-meta',
  // ssrAttribute: 'data-vue-meta-server-rendered',
  // tagIDKeyName: 'vmid',
  // refreshOnceOnNavigation: true,
})
*/
Vue.config.productionTip = false

new Vue({
  router,
  vuetify: new Vuetify({
    // moved vuetify here in order to give it i18n
    theme: {
      dark: false,
      themes: {
        light: {
          // primary: '#42A5F6',
          // secondary: '#050B1F',
          primary: '#E4007A', // Candy
          secondary: '#2F6FAC', // jean
          accent: '#204165',
          concrete: '#3C3C3B',
          jean: '#2F6FAC',
          candy: '#E4007A',
          sunflower: '#FFCE33',
          sea: '#77C9C1',
          background: '#FAFAFA',
        },
        dark: {
          primary: '#50778D',
          secondary: '#0B1C3D',
          accent: '#204165',
          concrete: '#3C3C3B',
          jean: '#2F6FAC',
          candy: '#E4007A',
          sunflower: '#FFCE33',
          sea: '#77C9C1',
        },
      },
    },
    lang: {
      t: (key, ...params) => i18n.t(key, params),
    },
  }),
  i18n,
  render: h => h(App),
}).$mount('#app')
